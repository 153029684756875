import {
  CheckCircleFilled,
  ClockCircleOutlined,
  ExclamationCircleFilled,
  QuestionCircleTwoTone,
  RobotOutlined,
  SyncOutlined,
  UserOutlined,
  UserSwitchOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import {
  MdOutlineScheduleSend,
  MdCancelScheduleSend,
  MdCheckCircle,
} from "react-icons/md";
import { ImSwitch } from "react-icons/im";
import { Tooltip } from "antd";
import { BsFillQuestionCircleFill } from "react-icons/bs";

export const getColumnStatus = (value) => {
  switch (value) {
    case "ERROR":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Error">
            <ExclamationCircleFilled
              style={{ color: "#dc4c64", fontSize: "16px" }}
            />
          </Tooltip>
        </div>
      );

    case "OK":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Success">
            <CheckCircleFilled style={{ color: "#14a44d", fontSize: "16px" }} />
          </Tooltip>
        </div>
      );
    case "WAITING":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Waiting">
            <ClockCircleOutlined
              style={{ color: "#ffc107", fontSize: "16px" }}
            />
          </Tooltip>
        </div>
      );
    case "MISUNDERSTOOD":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Misunderstood">
            <QuestionCircleTwoTone
              twoToneColor="#eb2f96"
              style={{ fontSize: "16px" }}
            />
          </Tooltip>
        </div>
      );
    case "RUNNING":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Running">
            <SyncOutlined spin style={{ color: "#1890ff", fontSize: "16px" }} />
          </Tooltip>
        </div>
      );
    case "ERROR_TIMEOUT":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Error Timeout">
            <WarningTwoTone
              twoToneColor="#ea96a4"
              style={{ fontSize: "16px" }}
            />
          </Tooltip>
        </div>
      );
    case "HANDOVER":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Handover">
            <UserSwitchOutlined
              style={{ color: "#000000", fontSize: "16px" }}
            />
          </Tooltip>
        </div>
      );
    case "NO_ANSWER":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="No answer">
            <ImSwitch style={{ color: "#dc4c64", fontSize: "16px" }} />
          </Tooltip>
        </div>
      );

    default:
      return <>Unknown Status Error: {value}</>;
  }
};

export const getColumnType = (value) => {
  switch (value) {
    case "INBOUND":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Inbound">
            <RobotOutlined style={{ color: "#000000", fontSize: "16px" }} />
          </Tooltip>
        </div>
      );

    case "OUTBOUND":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Outbound">
            <UserOutlined style={{ color: "#000000", fontSize: "16px" }} />
          </Tooltip>
        </div>
      );
    case "LEAP":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Leap">
            <RobotOutlined style={{ color: "#C0C0C0", fontSize: "16px" }} />
          </Tooltip>
        </div>
      );

    default:
      return <>Unknown Type Error: {value}</>;
  }
};
export const getColumnState = (value) => {
  switch (value) {
    case "ENABLED":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Enabled">
            <MdCheckCircle
              style={{ color: "rgb(20 164 77 / 79%)", fontSize: "19px" }}
            />
          </Tooltip>
        </div>
      );

    case "DISABLED":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Disabled">
            <MdCheckCircle style={{ color: "#D3D3D3", fontSize: "19px" }} />
          </Tooltip>
        </div>
      );

    default:
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Empty State">
            <div style={{ textAlign: "center" }}>
              <BsFillQuestionCircleFill
                style={{ color: "#d3d3d3 ", fontSize: "17px" }}
              />
            </div>
          </Tooltip>
        </div>
      );
  }
};

export const getColumnSchedule = (value) => {
  switch (value) {
    case "YES":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Scheduled">
            <div style={{ textAlign: "center" }}>
              <MdOutlineScheduleSend
                style={{ color: "rgba(205, 84, 71, 0.8) ", fontSize: "22px" }}
              />
            </div>
          </Tooltip>
        </div>
      );

    case "NOT":
      return (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Not Scheduled">
            <div style={{ textAlign: "center" }}>
              <MdCancelScheduleSend
                style={{ color: "#d3d3d3 ", fontSize: "21px" }}
              />
            </div>
          </Tooltip>
        </div>
      );

    default:
      return <>Unknown schedule: {value}</>;
  }
};
