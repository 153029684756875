import { Popconfirm, Space } from "antd";
import { MdOutlineScheduleSend, MdCancelScheduleSend } from "react-icons/md";
import StlButton from "components/shared/StlButton";
import { deepCopy, sortColumnFunc } from "utils";
import { getColumnState } from "utils/columnsUtils";

const prepareColumns = (columnsRaw, isEditing) => {
  const cols = deepCopy(columnsRaw);

  const mergedColumns = cols.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing,
      }),
    };
  });
  return mergedColumns;
};

export const getVoiceScenariosColumns = (columnsConfig) => {
  const defaultConfig = {
    schedules: null,
    deleteScenario: null,
    showDetail: null,
    isEditing: null,
    save: null,
    edit: null,
    execute: null,
    buttonStyle: null,
    handleMouseEnter: null,
    handleMouseLeave: null,
  };
  const params = { ...defaultConfig, ...columnsConfig };
  const columns = [
    /*  { title: "ID", dataIndex: "id", key: "id", dataType: "text" }, */
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      dataType: "text",
      inputType: "text",
      sorter: (a, b) => {
        return b.name.localeCompare(a.name);
      },
    },
    {
      title: "Type",
      dataIndex: "scenarioType",
      key: "type",
      editable: true,
      dataType: "text",
      sorter: sortColumnFunc("scenarioType"),
      filters: [
        {
          text: "standard",
          value: "STANDARD",
        },
        {
          text: "background",
          value: "BACKGROUND",
        },
      ],
      onFilter: (value, record) => {
        const scenarioType = record?.scenarioType ?? "";
        return scenarioType.indexOf(value) === 0;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      editable: true,
      inputType: "text",
      dataType: "text",
      sorter: (a, b) => {
        return b.description.localeCompare(a.description);
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      editable: true,
      inputType: "selectBox",
      dataType: "text",
      render: getColumnState,
    },
    /*  {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      dataType: "text",
      inputType: "text",
      render: (stringDate) => readableDate(stringDate),
    }, */
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      editable: true,
      dataType: "text",
      inputType: "selectBox",
      sorter: sortColumnFunc("userType"),
      filters: [
        {
          text: "consumer",
          value: "consumer",
        },
        {
          text: "business",
          value: "business",
        },
        {
          text: "next_consumer",
          value: "next_consumer",
        },
        {
          text: "simple_consumer",
          value: "simple_consumer",
        },
        {
          text: "simple_business",
          value: "simple_business",
        },
      ],
      onFilter: (value, record) => {
        const userType = record?.userType ?? "";
        return userType.indexOf(value) === 0;
      },
    },
    /*  {
      title: () => <Tooltip title="Max Levenshtein Distance">MLD</Tooltip>,
      dataIndex: "maxLevenshteinDistance",
      key: "maxLevenshteinDistance",
      editable: true,
      dataType: "number",
      inputType: "number",
    },
    {
      title: () => <Tooltip title="Error Execution Time">EET</Tooltip>,
      dataIndex: "errorTime",
      key: "errorExecutionTime",
      editable: true,
      dataType: "number",
      inputType: "number",
    },
    {
      title: () => <Tooltip title="Warning Execution Time">WET</Tooltip>,
      dataIndex: "warningTime",
      key: "warningExecutionTime",
      editable: true,
      dataType: "number",
      inputType: "number",
    }, 
      {
      title: "From",
      dataIndex: "fromNumber",
      key: "fromNumber",
      editable: true,
      dataType: "text",
      inputType: "text",
    },
    {
      title: "To",
      dataIndex: "toNumber",
      key: "toNumber",
      editable: true,
      dataType: "toNumber",
      inputType: "toNumber",
    }, */
  ];

  if (params.schedules) {
    columns.push({
      title: "Schedules",
      dataIndex: "schedules",
      key: "schedules",
      render: (_, record) => {
        const row = params.schedules[record.id] ?? null;
        if (row && row.length > 0) {
          return (
            <div style={{ textAlign: "center" }}>
              <MdOutlineScheduleSend
                style={{ color: "rgba(205, 84, 71, 0.8) ", fontSize: "22px" }}
              />
            </div>
          );
        }
        return (
          <div style={{ textAlign: "center" }}>
            <MdCancelScheduleSend
              style={{ color: "#d3d3d3 ", fontSize: "21px" }}
            />
          </div>
        );
      },
    });
  }

  if (params.deleteScenario) {
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => {
        const magnifier = (
          <StlButton
            iconName="zoomIn"
            onClickHandler={() => {
              params.showDetail(record);
            }}
          />
        );
        return (
          <Space>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => params.deleteScenario(record.id)}
            >
              <StlButton iconName="delete" />
            </Popconfirm>
            {magnifier}
          </Space>
        );
      },
    });
  } else {
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => {
        return params.isEditing ? (
          <>
            <StlButton
              iconName="save"
              onClickHandler={params.save}
              buttonStyle={{
                marginRight: 8,
              }}
            ></StlButton>

            {params.isBeingAdded ? null : (
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => params.cancel()}
              >
                <StlButton
                  iconName="cancel"
                  buttonStyle={params.buttonStyle}
                  onMouseEnter={params.handleMouseEnter}
                  onMouseLeave={params.handleMouseLeave}
                />
              </Popconfirm>
            )}
          </>
        ) : (
          <Space>
            <StlButton
              iconName="edit"
              onClickHandler={() => params.edit(record)}
            />
            <Popconfirm
              title="Sure to execute?"
              onConfirm={() => params.execute(record)}
            >
              <StlButton iconName="play" />
            </Popconfirm>
          </Space>
        );
      },
    });
  }

  //return columns;
  return prepareColumns(columns, params.isEditing);
};
