import React, { useState } from "react";
import { useSelector } from "react-redux";
import { InputNumber, Select } from "antd";
import StlFormItem from "components/shared/StlFormItem";
import { getInputNode } from "utils";

const options = {
  scenarioType: [
    {
      value: "STANDARD",
      label: "standard",
    },
    {
      value: "BACKGROUND",
      label: "background",
    },
  ],
  userType: [
    {
      value: "consumer",
      label: "consumer",
    },
    {
      value: "business",
      label: "business",
    },
    {
      value: "next_consumer",
      label: "next_consumer",
    },
    {
      value: "simple_business",
      label: "simple_business",
    },
    {
      value: "simple_consumer",
      label: "simple_consumer",
    },
  ],
  state: [
    { value: "ENABLED", label: "Enabled" },
    { value: "DISABLED", label: "Disabled" },
  ],
};
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  inputValues,
  record,
  index,
  children,
  ...restProps
}) => {
  const [value, setValue] = useState("");

  const { Option } = Select;

  const warningBiggerThanError = useSelector(
    (store) => store.displayScenarios.warningBiggerThanError
  );

  let inputNode;
  switch (inputType) {
    case "select":
      inputNode = (
        <Select
          placeholder="Select Type"
          style={{ width: 110 }}
          defaultValue={record.type}
        >
          {inputValues.map(({ title, value, key }) => (
            <Option value={value} key={key}>
              {title}
            </Option>
          ))}
        </Select>
      );
      break;
    case "number":
      inputNode = <InputNumber type="number" min={0} />;
      break;
    case "selectBox":
      inputNode = getInputNode(inputType, dataIndex, value, setValue, options);
      break;
    default:
      //inputNode = <TextareaAutosize autoFocus style={{ width: "100%" }} />;
      inputNode = getInputNode(inputType);
  }

  const isOptional = [
    "consumerNumber",
    "consumerType",
    "consumerForce",
    "consumerS",
    "businessNumber",
    "businessUsername",
    "businessCustomerCode",
    "businessProfile",
    "businessSegment",
    "businessS",
  ].includes(dataIndex);

  const checkCustomTitles = () => {
    const customTitles = [
      "maxLevenshteinDistance",
      "errorExecutionTime",
      "warningExecutionTime",
    ].includes(dataIndex);
    return `Please Input ${customTitles ? dataIndex : title}!`;
  };

  const rules = [
    isOptional
      ? { required: false }
      : { required: true, message: checkCustomTitles() },
  ];

  return (
    <td {...restProps} key={`${index}h`}>
      {editing ? (
        <div>
          <StlFormItem dataIndex={dataIndex} title={title} rules={rules}>
            {inputNode}
          </StlFormItem>
          <p style={{ fontSize: "small", color: "red" }}>
            {warningBiggerThanError &&
            ["errorExecutionTime", "warningExecutionTime"].includes(dataIndex)
              ? `Warning time can't be bigger than Error time.`
              : null}
          </p>
        </div>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
