import { createSlice } from "@reduxjs/toolkit";
import {
  editSchedulings,
  getAllSchedulings,
  getAllVoiceSchedulings,
} from "./voiceSchedulingsThunk";

const initialState = {
  allSchedulings: [],
  schedulings: [],
  columns: [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      width: "5%",
      align: "center",
    },
    { title: "Scheduling", dataIndex: "scheduling", key: "scheduling" },
  ],
  loading: false,
  failed: false,
};

export const voiceSchedulingSlice = createSlice({
  name: "voiceScheduling",
  initialState,
  reducers: {
    addScheduling: (state) => {
      state.schedulings.push({
        scheduling: "0 0/15 8-17 ? * MON-FRI *",
      });
    },
    deleteScheduling: (state) => {
      state.schedulings.pop();
    },
  },
  extraReducers: {
    [getAllVoiceSchedulings.pending]: (state) => {
      //console.log("getAllVoiceSchedulings.pending")
      state.failed = false;
      state.loading = true;
    },
    [getAllVoiceSchedulings.fulfilled]: (state, action) => {
      //console.log("getAllVoiceSchedulings.fulfilled")
      state.loading = false;
      state.allSchedulings = action.payload;
    },
    [getAllVoiceSchedulings.rejected]: (state) => {
      state.loading = false;
      state.failed = true;
    },

    [getAllSchedulings.pending]: (state) => {
      state.failed = false;
      state.loading = true;
    },
    [getAllSchedulings.fulfilled]: (state, action) => {
      state.loading = false;
      state.schedulings = action.payload;
    },
    [getAllSchedulings.rejected]: (state) => {
      state.loading = false;
      state.failed = true;
    },

    [editSchedulings.pending]: (state) => {
      state.failed = false;
      state.loading = true;
    },
    [editSchedulings.fulfilled]: (state) => {
      state.loading = false;
    },
    [editSchedulings.rejected]: (state) => {
      state.loading = false;
      state.failed = true;
    },
  },
});

export const { addScheduling, deleteScheduling } = voiceSchedulingSlice.actions;

export default voiceSchedulingSlice.reducer;
