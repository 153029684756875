import { createSlice, current } from "@reduxjs/toolkit";
import {
  addNewConfiguration,
  deleteConfigurationsById,
  editConfiguration,
  getAllConfigurations,
  getConfigurationById,
} from "./sentinelConfigurationThunk";

const name = "sentinelConfigurationSlice";
const initialState = createInitalState();
const reducers = createReducers();
const extraReducers = createExtraReducers();

export const sentinelConfigurationSlice = createSlice({
  name,
  initialState,
  reducers,
  extraReducers,
});

export const {
  addSentinelConfiguration,
  setIsEditing,
  setIsBeingAdded,
  setLoading,
} = sentinelConfigurationSlice.actions;

export default sentinelConfigurationSlice.reducer;

function createInitalState() {
  return {
    configurations: [],
    columns: [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        responsive: ["md", "lg"],
      },
      { title: "Param", dataIndex: "param", key: "param" },
      { title: "Value", dataIndex: "value", key: "value" },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        responsive: ["lg"],
      },
    ],
    speechToTexedStep: {},
    loading: false,
    failed: false,
    editing: false,
    isBeingAdded: false,
    selectedStepType: "",
  };
}

function createReducers() {
  return {
    addSentinelConfiguration: (state, action) => {
      state.configurations.unshift(action.payload);
    },
    setIsEditing: (state, action) => {
      state.editing = action.payload;
    },
    setIsBeingAdded: (state, action) => {
      state.isBeingAdded = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  };
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...create(),
    ...getById(),
    ..._delete(),
    ...update(),
  };
  function getAll() {
    const { pending, fulfilled, rejected } = getAllConfigurations;
    return {
      [pending]: (state) => {
        state.loading = true;
        state.failed = false;
      },
      [fulfilled]: (state, action) => {
        state.configurations = action.payload;
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.rejected = true;
      },
    };
  }
  function create() {
    const { pending, fulfilled, rejected } = addNewConfiguration;

    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state) => {
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }

  function getById() {
    const { pending, fulfilled, rejected } = getConfigurationById;

    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, action) => {
        state.loading = false;
        const currentState = current(state);
        const index = currentState.configurations.findIndex(
          (configuration) => configuration.id === action.payload.id
        );
        index > -1
          ? state.configurations.splice(index, 1, action.payload)
          : state.configurations.push(action.payload);
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }
  function _delete() {
    const { pending, fulfilled, rejected } = deleteConfigurationsById;
    return {
      [pending]: (state) => {
        state.loading = true;
        state.failed = false;
      },
      [fulfilled]: (state) => {
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }

  function update() {
    const { pending, fulfilled, rejected } = editConfiguration;

    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state) => {
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }
}
