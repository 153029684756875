import React from "react";
import tobiIcon from "../../assets/ProfileTobi.svg";
import styles from "./NotFoundPage.module.scss";
import StlFrame from "components/shared/StlFrame";

export default function NotFoundPage() {
  return (
    <StlFrame>
      <div className={styles.NotFoundPage}>
        <h1>Error</h1>
        <p>404</p>
        <div>
          <img className="tobiImage" src={tobiIcon} alt="" />
        </div>
        <h1> Page not found</h1>
      </div>
    </StlFrame>
  );
}
