import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";
import { CloseCircleTwoTone } from "@ant-design/icons";

const AlertMessage = ({ message }) => {
  return (
    <Alert
      style={{
        float: "left",
        marginLeft: "10px",
        paddingBottom: "2px",
        paddingTop: "5px",
        borderRadius: "5px",
      }}
      message={message}
      type="error"
      closable
      icon={<CloseCircleTwoTone twoToneColor="red" />}
      showIcon
    />
  );
};

AlertMessage.propType = { message: PropTypes.string };
AlertMessage.defaultProps = {
  message: "Failed To Connect To server",
};
export default AlertMessage;
