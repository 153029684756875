export const SCENARIO_API_ENDPOINT =
  process.env.REACT_APP_API_GATEWAY + "/scenariosuite/";

export const SCHEDULER_API_ENDPOINT =
  process.env.REACT_APP_API_GATEWAY + "/scheduler/";

export const REPORT_API_ENDPOINT =
  process.env.REACT_APP_API_GATEWAY + "/reportsuite/";

export const VOICE_API_ENDPOINT =
  process.env.REACT_APP_API_GATEWAY + "/voiceclient/";

export const DISPLAY_API_ENDPOINT =
  process.env.REACT_APP_API_GATEWAY + "/displayclient/";
