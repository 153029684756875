import { Popconfirm, Space } from "antd";
import StlButton from "components/shared/StlButton";
import { useHover } from "hooks/useHover";
import React from "react";

const DisplayScenarioBaseDataActions = ({
  isEditing,
  isBeingAdded,
  recordData,
  onSave,
  onEdit,
  onCancel,
  onExecute,
}) => {
  const { buttonStyle, handleMouseEnter, handleMouseLeave } = useHover();

  return isEditing ? (
    <>
      <div>
        <StlButton
          iconName="save"
          buttonStyle={{ marginRight: 8 }}
          onClickHandler={onSave}
        />
      </div>

      {isBeingAdded ? null : (
        <Popconfirm title="Sure to cancel?" onConfirm={onCancel}>
          <div>
            <StlButton
              iconName="cancel"
              buttonStyle={buttonStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </div>
        </Popconfirm>
      )}
    </>
  ) : (
    <Space>
      <div>
        <StlButton
          iconName="edit"
          buttonStyle={{
            marginRight: 8,
          }}
          onClickHandler={() => onEdit(recordData)}
        />
      </div>

      <Popconfirm
        title="Sure to execute?"
        onConfirm={() => onExecute(recordData)}
      >
        <StlButton iconName="play" />
      </Popconfirm>
    </Space>
  );
};

export default DisplayScenarioBaseDataActions;
