import { createAsyncThunk } from "@reduxjs/toolkit";
import { SCHEDULER_API_ENDPOINT } from "utils";
import { fetchWrapper } from "utils/fetchWrapper";

const VOICE_SCHEDULING_BASEURL = SCHEDULER_API_ENDPOINT + "voice/";
const name = "voiceScheduling";
export const getAllVoiceSchedulings = createAsyncThunk(
  `${name}/getAllVoiceSchedulings`,
  () => {
    return fetchWrapper
      .get(VOICE_SCHEDULING_BASEURL + "schedulings")
      .catch((error) => console.log(error));
  }
);

export const getAllSchedulings = createAsyncThunk(
  `${name}/getAllSchedulings`,
  (scenarioID) => {
    return fetchWrapper
      .get(VOICE_SCHEDULING_BASEURL + scenarioID + "/scheduling")
      .catch((error) => console.log(error));
  }
);

export const editSchedulings = createAsyncThunk(
  `${name}/editSchedulings`,
  (data) => {
    return fetchWrapper
      .put(
        VOICE_SCHEDULING_BASEURL + data.scenarioID + "/scheduling",
        data.schedulings
      )
      .catch((error) => console.log(error));
  }
);
