import React, { useState } from "react";
import { getInputNode } from "utils";
import StlFormItem from "components/shared/StlFormItem";

const options = {
  state: [
    {
      value: "ENABLED",
      label: "Enabled",
    },
    {
      value: "DISABLED",
      label: "Disabled",
    },
  ],
};

const VoiceScenariosEditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const [value, setValue] = useState(record?.state);

  let inputNode = getInputNode(inputType, dataIndex, value, setValue, options);

  return (
    <td {...restProps}>
      {editing ? (
        <StlFormItem dataIndex={dataIndex} title={title}>
          {inputNode}
        </StlFormItem>
      ) : (
        children
      )}
    </td>
  );
};

export default VoiceScenariosEditableCell;
