import { createSlice } from "@reduxjs/toolkit";

const name = "menuSlice";
const initialState = createInitialState();
const reducers = createReducers();

export const menuSlice = createSlice({
  name: name,
  initialState,
  reducers,
});

export const { setActivePage } = menuSlice.actions;

export default menuSlice.reducer;

function createInitialState() {
  return {
    activePage: "display",
  };
}

function createReducers() {
  return {
    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },
  };
}
