import { deepCopy } from "utils";

export const getBackgroundScenariosList = (backgroundScenariosRaw) => {
  const backgroundScenarios = [];
  if (backgroundScenariosRaw) {
    const copiedScenarios = deepCopy(backgroundScenariosRaw);
    copiedScenarios.forEach(({ name }) => {
      backgroundScenarios.push({
        value: name,
        label: name,
      });
    });
  }
  return backgroundScenarios;
};
