import React from "react";
import StlFrame from "components/shared/StlFrame";
import VoiceScenarios from "components/modules/voiceArea/VoiceScenarios";

function VoicePage(props) {
  return (
    <StlFrame>
      <VoiceScenarios />
    </StlFrame>
  );
}

VoicePage.propTypes = {};

export default VoicePage;
