import { Popconfirm } from "antd";
import StlButton from "components/shared/StlButton";

const prepareColumns = (columns, editingKey, isEditing) => {
  return columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
};

export const getMsisdnColumns = (columnsConfig) => {
  const {
    handleDelete,
    handleEdit,
    handleSave,
    handleCancel,
    editingKey,
    isEditing,
  } = columnsConfig;
  const msisdnColumns = [
    {
      title: "Des",
      dataIndex: "des",
      key: "des",
      editable: true,
      dataType: "text",
      inputType: "text",
    },
    {
      title: "Msisdn",
      dataIndex: "msisdn",
      key: "msisdn",
      editable: true,
      dataType: "text",
      inputType: "text",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      editable: true,
      dataType: "text",
      inputType: "text",
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      key: "enabled",
      editable: true,
      dataType: "text",
      inputType: "selectBox",
      render: (text) => String(text),
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
      editable: true,
      dataType: "text",
      inputType: "number",
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      editable: true,
      dataType: "text",
      inputType: "selectBox",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      editable: true,
      dataType: "text",
      inputType: "text",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      editable: true,
      dataType: "text",
      inputType: "text",
    },
    {
      title: "Puk",
      dataIndex: "puk",
      key: "puk",
      editable: true,
      dataType: "text",
      inputType: "text",
    },
    {
      title: "Serial Number",
      dataIndex: "serialNumber",
      key: "serialNumber",
      editable: true,
      dataType: "text",
      inputType: "text",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      width: "8%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <StlButton
              iconName="save"
              buttonStyle={{ float: "left", marginRight: 8 }}
              onClickHandler={() => handleSave(record)}
            />
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => handleCancel(record.id)}
            >
              <div>
                <StlButton iconName="cancel" />
              </div>
            </Popconfirm>
          </>
        ) : (
          <>
            <StlButton
              iconName="edit"
              buttonStyle={{ float: "left", marginRight: 8 }}
              onClickHandler={() => handleEdit(record)}
            />
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <div>
                <StlButton iconName="delete" />
              </div>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  return prepareColumns(msisdnColumns, editingKey, isEditing);
};
