import { createAsyncThunk } from "@reduxjs/toolkit";
import { REPORT_API_ENDPOINT, SCENARIO_API_ENDPOINT } from "utils";
import { fetchWrapper } from "utils/fetchWrapper";

const name = "voicePerformedTestDetailSlice";

export const getPerformedTestsDetailById = createAsyncThunk(
  `${name}/getPerformedTestsDetailById`,
  (idScenario) => {
    return fetchWrapper
      .get(REPORT_API_ENDPOINT + "voice/" + idScenario)
      .catch((error) => {
        console.log(error);
      });
  }
);

export const getActions = createAsyncThunk(`${name}/getActions`, () => {
  return fetchWrapper.post(SCENARIO_API_ENDPOINT + "config/search", {
    criteria: [{ field: "param", operator: "eq", value: "checkType" }],
  });
});
