import { createSlice } from "@reduxjs/toolkit";
import {
  editSchedulesByScenarioId,
  getAllDisplaySchedules,
  getAllSchedulesByScenarioId,
} from "./displaySchedulingsThunk";

const name = "displaySchedules";
const initialState = createInitialState();
const reducers = createReducers();
const extraReducers = createExtraReducers();

export const displaySchedulingSlice = createSlice({
  name: name,
  initialState,
  reducers,
  extraReducers,
});

export const { addScheduling, deleteScheduling } =
  displaySchedulingSlice.actions;

export default displaySchedulingSlice.reducer;

function createInitialState() {
  return {
    allSchedulings: [],
    schedulings: [],
    columns: [
      {
        title: "Number",
        dataIndex: "number",
        key: "number",
        width: "5%",
        align: "center",
      },
      { title: "Scheduling", dataIndex: "scheduling", key: "scheduling" },
    ],
    loading: false,
    failed: false,
    pending: false,
  };
}

function createReducers() {
  return {
    addScheduling: (state) => {
      state.schedulings.push({ scheduling: "0 0/15 8-17 ? * MON-FRI *" });
    },
    deleteScheduling: (state) => {
      state.schedulings.pop();
    },
  };
}

function createExtraReducers() {
  return {
    ...getAllSchedulings(),
    ...getAllDisplaySchedulings(),
    ...editSchedulings(),
  };
  function getAllSchedulings() {
    const { pending, fulfilled, rejected } = getAllSchedulesByScenarioId;
    return {
      [pending]: (state) => {
        state.failed = false;
        state.loading = true;
      },
      [fulfilled]: (state, action) => {
        state.loading = false;
        state.schedulings = action.payload;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }

  function getAllDisplaySchedulings() {
    const { pending, fulfilled, rejected } = getAllDisplaySchedules;
    return {
      [pending]: (state) => {
        state.failed = false;
        state.loading = true;
      },
      [fulfilled]: (state, action) => {
        state.loading = false;
        state.allSchedulings = action.payload;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }

  function editSchedulings() {
    const { pending, fulfilled, rejected } = editSchedulesByScenarioId;
    return {
      [pending]: (state) => {
        state.failed = false;
        state.loading = true;
      },
      [fulfilled]: (state) => {
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }
}
