import { createSlice, current } from "@reduxjs/toolkit";
import {
  getDisplayScenarios,
  addNewDisplayScenario,
  deleteDisplayScenarioById,
  editDisplayScenario,
  getDisplayScenarioById,
  getAllBackgroundScenarios,
} from "./displayScenariosThunk";

//create Slice

const name = "displayScenariosSlice";
const initialState = createInitialState();
const reducers = createReducers();
const extraReducers = createExtraReducer();

export const displayScenariosSlice = createSlice({
  name: name,
  initialState,
  reducers,
  extraReducers,
});

export const {
  addDisplayScenario,
  deletelastForCancel,
  addStep,
  deleteStep,
  setIsEditing,
  setIsBeingAdded,
  setLoading,
  setWarningBiggerThanError,
  setSelectedStepType,
  setIndexOrText,
  setTabActiveKey,
} = displayScenariosSlice.actions;

export default displayScenariosSlice.reducer;

//implementation

function createInitialState() {
  return {
    scenarios: [],
    newScenarioModel: {
      id: null,
      url: null,
      maxLevenshteinDistance: null,
      errorExecutionTime: null,
      warningExecutionTime: null,
      name: null,
      description: null,
      steps: [
        {
          id: null,
          stepId: "START",
          type: "START",
        },
        {
          id: null,
          stepId: "END",
          type: "END",
        },
      ],
    },
    selectedStepType: "",
    loading: false,
    failed: false,
    editing: false,
    isBeingAdded: false,
    warningBiggerThanError: false,
    indexOrText: { index: true, text: true },
    tabActiveKey: "steps",
    backgroundScenariosList: [],
  };
}

function createReducers() {
  return {
    addDisplayScenario: (state, action) => {
      state.scenarios.unshift(action.payload);
    },
    deleteLastForCancel: (state) => {
      state.scenarios.pop();
    },
    addStep: (state, action) => {
      let allScenarios = JSON.parse(JSON.stringify(current(state.scenarios)));
      let scenario = allScenarios.filter(
        (e) => e.id === action.payload.scenarioID
      )[0];
      const newStep = {
        id: "New Step",
        stepId: null,
        type: null,
        inputField: null,
        text: null,
        index: null,
        wait: null,
        maxLevenshteinDistance: null,
        errorExecutionTime: null,
        warningExecutionTime: null,
      };
      let steps = scenario.steps;
      steps.splice(
        steps.findIndex((e) => e.id === action.payload.stepID) + 1,
        0,
        newStep
      );
      scenario.steps = steps;

      state.scenarios.splice(
        state.scenarios.findIndex((e) => e.id === action.payload.scenarioID),
        1,
        scenario
      );
    },
    deleteStep: (state, action) => {
      let allScenarios = JSON.parse(JSON.stringify(current(state.scenarios)));
      let scenario = allScenarios.filter(
        (e) => e.id === action.payload.scenarioID
      )[0];
      let steps = scenario.steps.filter((e) => e.id !== action.payload.stepID);
      scenario.steps = steps;

      state.scenarios.splice(
        state.scenarios.findIndex((e) => e.id === action.payload.scenarioID),
        1,
        scenario
      );
    },
    setIsEditing: (state, action) => {
      state.editing = action.payload;
    },
    setIsBeingAdded: (state, action) => {
      state.isBeingAdded = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setWarningBiggerThanError: (state, action) => {
      state.warningBiggerThanError = action.payload;
    },

    setSelectedStepType: (state, action) => {
      state.selectedStepType = action.payload;
    },
    setIndexOrText: (state, action) => {
      state.indexOrText = action.payload;
    },
    setTabActiveKey: (state, action) => {
      state.tabActiveKey = action.payload;
    },
  };
}

function createExtraReducer() {
  return {
    ...getAll(),
    ...create(),
    ...getOne(),
    ..._delete(),
    ...edit(),
    ..._getAllBackgroundScenarios(),
  };

  function getAll() {
    const { pending, fulfilled, rejected } = getDisplayScenarios;
    return {
      [pending]: (state) => {
        state.loading = true;
        state.failed = false;
      },
      [fulfilled]: (state, action) => {
        state.scenarios = action.payload;
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.rejected = true;
      },
    };
  }

  function _delete() {
    const { pending, fulfilled, rejected } = deleteDisplayScenarioById;
    return {
      [pending]: (state) => {
        state.loading = true;
        state.failed = false;
      },
      [fulfilled]: (state) => {
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }
  function create() {
    const { pending, fulfilled, rejected } = addNewDisplayScenario;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state) => {
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }
  function edit() {
    const { pending, fulfilled, rejected } = editDisplayScenario;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state) => {
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }
  function getOne() {
    const { pending, fulfilled, rejected } = getDisplayScenarioById;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, action) => {
        state.loading = false;
        const currentState = current(state);
        const index = currentState.scenarios.findIndex(
          (scenario) => scenario.id === action.payload.id
        );
        index > -1
          ? state.scenarios.splice(index, 1, action.payload)
          : state.scenarios.push(action.payload);
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }
  function _getAllBackgroundScenarios() {
    const { pending, fulfilled, rejected } = getAllBackgroundScenarios;
    return {
      [pending]: (state) => {
        state.loading = true;
        state.failed = false;
      },
      [fulfilled]: (state, action) => {
        state.loading = false;
        state.backgroundScenariosList = action.payload;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }
}
