import { configureStore } from "@reduxjs/toolkit";
import displayScenariosSlice from "./slices/display/displayScenariosSlice";
import voiceScenariosSlice from "./slices/voice/voiceScenariosSlice";
import displaySchedulingsSlice from "./slices/display/displaySchedulingsSlice";
import voiceSchedulingsSlice from "./slices/voice/voiceSchedulingsSlice";
import personalInfoSlice from "./slices/user/personalInfoSlice";
import sentinelConfigurationSlice from "./slices/configuration/sentinelConfigurationSlice";
import voicePerformedTestSlice from "./slices/voice/voicePerformedTest/voicePerformedTestSlice";
import voicePerformedTestDetailSlice from "./slices/voice/voicePerformedTestDetail/voicePerformedTestDetailSlice";
import menuSlice from "./slices/menu/menuSlice";
import msisdnSlice from "./slices/msisdn/msisdnSlice";

export const store = configureStore({
  reducer: {
    displayScenarios: displayScenariosSlice,
    displaySchedulings: displaySchedulingsSlice,
    voiceScenarios: voiceScenariosSlice,
    voiceSchedulings: voiceSchedulingsSlice,
    personalInfo: personalInfoSlice,
    sentinelConfiguration: sentinelConfigurationSlice,
    voicePerformedTests: voicePerformedTestSlice,
    voicePerformedTestsDetail: voicePerformedTestDetailSlice,
    menu: menuSlice,
    msisdn: msisdnSlice,
  },
  devTools: true,
});
