import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { BsSim } from "react-icons/bs";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { RiUserVoiceLine } from "react-icons/ri";
import { MdOutlineDisplaySettings } from "react-icons/md";
import styles from "./StlMenu.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "features/slices/menu/menuSlice";
const items = [
  {
    key: "display",
    label: "Display Area",
    icon: (
      <IoChatboxEllipsesOutline
        style={{ position: "relative", top: "2", fontSize: "18" }}
      />
    ),
  },
  {
    key: "voice",
    label: "Voice Area",
    icon: (
      <RiUserVoiceLine
        style={{ position: "relative", top: "2", fontSize: "16" }}
      />
    ),
  },
  {
    key: "configuration",
    label: "Configuration Area",
    icon: (
      <MdOutlineDisplaySettings
        style={{ position: "relative", top: "3", fontSize: "18" }}
      />
    ),
  },
  {
    key: "msisdn",
    label: "Msisdn Area",
    icon: <BsSim />,
  },
];
const StlMenu = (props) => {
  const dispatch = useDispatch();
  const activePage = useSelector((store) => store.menu.activePage);

  const navigate = useNavigate();
  const navigateHandle = (path) => {
    navigate("/" + path.key);
    dispatch(setActivePage(path.key));
  };

  return (
    <Menu
      mode="horizontal"
      className={styles.StlMenu}
      selectedKeys={[activePage]}
      onClick={navigateHandle}
      items={items}
    />
  );
};

export default StlMenu;
