import { useState } from "react";

export const useHover = () => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const buttonStyle = {
    borderColor: isHover ? "red" : "transparent",
  };
  return {
    buttonStyle,
    handleMouseEnter,
    handleMouseLeave,
  };
};
