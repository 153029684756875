import { createAsyncThunk } from "@reduxjs/toolkit";
import DisplayService from "services/DisplayService";

const name = "displayScenariosSlice";

export const getDisplayScenarios = createAsyncThunk(
  `${name}/getDisplayScenarios`,
  () => {
    return DisplayService.getAll();
  }
);

export const deleteDisplayScenarioById = createAsyncThunk(
  `${name}/deleteDisplayScenarioByIds`,
  (id) => {
    return DisplayService.remove(id);
  }
);

export const addNewDisplayScenario = createAsyncThunk(
  `${name}/addNewDisplayScenario`,
  (newScenario) => {
    return DisplayService.create(newScenario);
  }
);

export const editDisplayScenario = createAsyncThunk(
  `${name}/editDisplayScenario`,
  (editedScenario) => {
    return DisplayService.update(editedScenario);
  }
);

export const getDisplayScenarioById = createAsyncThunk(
  `${name}/getDisplayScenarioById`,
  (id) => {
    return DisplayService.getOne(id);
  }
);

export const getAllBackgroundScenarios = createAsyncThunk(
  `${name}/getAllBackgroundScenarios`,
  () => {
    return DisplayService.getBackgroundScenarios();
  }
);
