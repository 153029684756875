export const prepareScenarios = (scenariosRaw) => {
  const copiedScenarios = JSON.parse(JSON.stringify(scenariosRaw));
  if (copiedScenarios.length > 0) {
    if (copiedScenarios.at(-1).status) {
      copiedScenarios.pop();
    }
    copiedScenarios.forEach((scenario) => (scenario.key = scenario.id));
  }

  return copiedScenarios;
};
