import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import Styles from "./StlTable.module.scss";
import Card from "antd/lib/card/Card";

const StlTable = ({
  style,
  components,
  dataSource,
  columns,
  rowSelection,
  loading,
  pagination,
  ...rest
}) => {
  return (
    <>
      <Card>
        <Table
          className={Styles.StlTable}
          style={style}
          components={components}
          dataSource={dataSource}
          columns={columns}
          rowSelection={rowSelection}
          loading={loading}
          pagination={pagination}
          bordered
          size="small"
          scroll={{ x: 400 }}
          {...rest}
        />
      </Card>
    </>
  );
};

StlTable.propTypes = {
  style: PropTypes.object,
  components: PropTypes.object,
  dataSource: PropTypes.arrayOf(Object).isRequired,
  columns: PropTypes.arrayOf(Object).isRequired,
  rowSelection: PropTypes.object,
  loading: PropTypes.bool,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

StlTable.defaultProps = {
  pagination: false,
};

export default StlTable;
