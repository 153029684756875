import React from "react";
import StlFrame from "components/shared/StlFrame";
import SentinelConfiguration from "components/modules/configurationArea/SentinelConfiguration";

function ConfigurationPage() {
  return (
    <StlFrame>
      <SentinelConfiguration />
    </StlFrame>
  );
}

export default ConfigurationPage;
