import { Popconfirm, Select, Space, Tooltip } from "antd";
import StlButton from "components/shared/StlButton";
import { deepCopy } from "utils";

const { Option } = Select;

const stepTypes = [
  "READ",
  "WRITE",
  "WAIT",
  "CLICK",
  "READ_SELECTOR",
  "BACKGROUND",
  "INPUT",
  "USERNAME",
  "PASSWORD",
];

const editableFieldsByType = {
  READ: [
    "text",
    "maxLevenshteinDistance",
    "errorExecutionTime",
    "warningExecutionTime",
    "stepId",
  ],
  WRITE: ["inputField", "text", "stepId"],
  CLICK: ["text", "index", "stepId"],
  READ_SELECTOR: ["stepId"],
  WAIT: ["wait", "stepId"],
  START: ["stepId"],
  END: ["stepId"],
  BACKGROUND: ["text"],
  PASSWORD: ["stepId", "text"],
  USERNAME: ["stepId", "text"],
  INPUT: ["stepId", "text"],
};

const prepareColumns = (columnsRaw, editingKey, selectedStepType) => {
  const cols = deepCopy(columnsRaw);

  cols.forEach((e) => {
    if (e.dataIndex === "stepId") {
      e.editable = true;
    }
  });
  cols.forEach((e) => {
    let editableFields = editableFieldsByType[selectedStepType] || [];
    if (editableFields.includes(e.dataIndex)) {
      e.editable = true;
    }
  });

  const mergedColumns = cols.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: record.key === editingKey,
      }),
    };
  });

  return mergedColumns;
};
export const getDisplayStepsColumns = (
  editingKey,
  areMultipleSelected,
  isBeingAdded,
  scenarioIsBeingAdded,
  onSelectTypeHandler,
  save,
  cancel,
  buttonStyle,
  handleMouseEnter,
  handleMouseLeave,
  edit,
  deleteRow,
  addANewRow,
  selectedStepType
) => {
  const columns = [
    { title: "Step ID", dataIndex: "stepId", key: "stepId", inputType: "text" },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (_, record) => {
        if (record.type !== "START" && record.type !== "END") {
          return record.key === editingKey ? (
            <Select
              onSelect={(e) => {
                onSelectTypeHandler(e);
              }}
              placeholder="Type"
              defaultValue={record.type}
            >
              {stepTypes.map((e) => (
                <Option value={e} key={e}>
                  {e}
                </Option>
              ))}
            </Select>
          ) : (
            <div>{record.type}</div>
          );
        } else {
          return <div>{record.type}</div>;
        }
      },
    },
    {
      title: "Input",
      dataIndex: "inputField",
      key: "inputField",
      inputType: "text",
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
      inputType: selectedStepType === "BACKGROUND" ? "selectBox" : "text",
    },
    { title: "Index", dataIndex: "index", key: "index", inputType: "number" },
    { title: "Wait", dataIndex: "wait", key: "wait", inputType: "number" },
    {
      title: () => <Tooltip title="Max Levenshtein Distance">MLD</Tooltip>,
      dataIndex: "maxLevenshteinDistance",
      key: "maxLevenshteinDistance",
      inputType: "number",
    },
    {
      title: () => <Tooltip title="Error Execution Time">EET</Tooltip>,
      dataIndex: "errorExecutionTime",
      key: "errorExecutionTime",
      inputType: "number",
    },
    {
      title: () => <Tooltip title="Warning Execution Time">WET</Tooltip>,
      dataIndex: "warningExecutionTime",
      key: "warningExecutionTime",
      inputType: "number",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => {
        const disableConditions =
          editingKey !== "" ||
          areMultipleSelected ||
          isBeingAdded ||
          scenarioIsBeingAdded;

        return record.key === editingKey ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <StlButton
              buttonType="ghost"
              iconName="save"
              buttonStyle={{
                marginRight: 8,
              }}
              onClickHandler={() => save(record.id)}
              key={record.key + "saveButton"}
            />

            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => cancel(record.id)}
            >
              <StlButton
                iconName="cancel"
                buttonStyle={buttonStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                key={record.key + "cancelButton"}
              />
            </Popconfirm>
          </div>
        ) : (
          <Space>
            <StlButton
              iconName="edit"
              onClickHandler={() => edit(record)}
              disabled={disableConditions}
            />

            {record.type !== "START" ? (
              record.type !== "END" ? (
                <Popconfirm
                  title="sure to delete?"
                  onConfirm={() => deleteRow(record.id)}
                  disabled={disableConditions}
                >
                  <StlButton iconName="delete" disabled={disableConditions} />
                </Popconfirm>
              ) : null
            ) : null}
            {record.type !== "END" ? (
              <StlButton
                iconName="add"
                onClickHandler={() => addANewRow(record.id)}
                disabled={disableConditions}
              />
            ) : null}
          </Space>
        );
      },
    },
  ];
  return prepareColumns(columns, editingKey, selectedStepType);
};
