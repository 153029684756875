import React from "react";
import PropTypes from "prop-types";
import StlButton from "components/shared/StlButton";
import { PageHeader, Tabs } from "antd";

const StlAreaContainer = ({
  title,
  backButtonHandler,
  tabContents,
  scenarioDescription,
  isEditing,
  isBeingAdded,
}) => {
  const { tabs, activeKey, onChangeHandler } = tabContents;
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <StlButton
          onClickHandler={backButtonHandler}
          buttonType="dashed"
          ghost={false}
          iconName="goBack"
          style={{ marginLeft: "5px", color: "rgba(205, 84, 71, 0.8)" }}
          buttonShape="default"
          disabled={isEditing && !isBeingAdded}
        />

        <PageHeader title={title} />
      </div>
      {scenarioDescription}

      {tabs && tabs.length > 0 && (
        <Tabs
          items={tabs}
          activeKey={activeKey}
          onChange={onChangeHandler}
        ></Tabs>
      )}
    </>
  );
};

StlAreaContainer.propTypes = {
  title: PropTypes.string.isRequired,
  backButtonHandler: PropTypes.func.isRequired,
  tabContents: PropTypes.shape({
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        children: PropTypes.element.isRequired,
      })
    ).isRequired,
    activeKey: PropTypes.string.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
  }).isRequired,
  scenarioDescription: PropTypes.element.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isBeingAdded: PropTypes.bool.isRequired,
};

export default StlAreaContainer;
