import { Form } from "antd";
import AlertMessage from "components/shared/AlertMessage";
import StlButton from "components/shared/StlButton";
import StlFormItem from "components/shared/StlFormItem";
import StlTable from "components/shared/StlTable";
import {
  deleteDisplayScenarioById,
  getDisplayScenarios,
} from "features/slices/display/displayScenariosThunk";
import useDisplayScenarios from "hooks/useDisplayScenarios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import DisplayService from "services/DisplayService";

import { getInputNode } from "utils";

import { displayScenariosColumns } from "./DisplayScenariosColumns";

const options = {
  state: [
    {
      value: "ENABLED",
      label: "Enabled",
    },
    {
      value: "DISABLED",
      label: "Disabled",
    },
  ],
};
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  inputValues,
  record,
  index,
  children,
  ...restProps
}) => {
  const [value, setValue] = useState(record?.state);

  let inputNode = getInputNode(inputType, dataIndex, value, setValue, options);

  return (
    <td {...restProps}>
      {editing ? (
        <StlFormItem title={title} dataIndex={dataIndex}>
          {inputNode}
        </StlFormItem>
      ) : (
        children
      )}
    </td>
  );
};

const components = {
  body: {
    cell: EditableCell,
  },
};

const DisplayScenarios = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isBeingAdded = useSelector(
    (store) => store.displayScenarios.isBeingAdded
  );

  const newScenario = useSelector(
    (store) => store.displayScenarios.newScenarioModel
  );

  const schedules = useSelector(
    (store) => store.displaySchedulings.allSchedulings
  );

  const magnifierClickHandler = (record) => {
    navigate("/display/" + record.id);
  };

  const addANewRow = async () => {
    const newRowFromServer = await DisplayService.create(newScenario);
    const theID = newRowFromServer.id;
    dispatch(getDisplayScenarios());
    navigate("/display/" + theID);
  };

  const deleteScenario = async (id) => {
    dispatch(deleteDisplayScenarioById(id))
      .then(() => {
        dispatch(getDisplayScenarios());
      })
      .catch((err) => {
        console.log("An error ocurred while deleting the scenario", err);
      });
  };
  const { failed, scenarios, loading } = useDisplayScenarios();

  const displayColumnsConfig = {
    schedules,
    deleteScenario,
    magnifierClickHandler,
  };

  const columns = displayScenariosColumns(displayColumnsConfig);

  const [form] = Form.useForm();
  return (
    <Form form={form}>
      <div style={{ textAlign: "right" }}>
        {failed ? <AlertMessage /> : null}
        <StlButton
          buttonType="dashed"
          onClickHandler={addANewRow}
          buttonStyle={{
            marginBottom: "10px",
            color: "rgba(205, 84, 71, 0.8)",
          }}
          disabled={isBeingAdded}
          ghost={false}
          buttonShape="default"
        >
          Add Scenario
        </StlButton>
      </div>
      {scenarios && (
        <StlTable
          components={components}
          dataSource={scenarios}
          columns={columns}
          loading={loading}
          pagination={{ showSizeChanger: true }}
        />
      )}
    </Form>
  );
};

DisplayScenarios.propTypes = {};

export default DisplayScenarios;
