import { createSlice, current } from "@reduxjs/toolkit";
import { deepCopy, findObjectById, updateElementById } from "utils";
import {
  addNewVoiceScenario,
  deleteVoiceScenarioById,
  editVoiceScenario,
  getAllBackgroundScenarios,
  getAllVoiceScenarios,
  getOneVoiceScenarioById,
} from "./voiceScenariosThunk";

const name = "voiceScenariosSlice";
const initialState = createInitialState();
const reducers = createReducers();
const extraReducers = createExtraReducers();

export const voiceScenariosSlice = createSlice({
  name,
  initialState,
  reducers,
  extraReducers,
});

export const {
  addVoiceScenario,
  deletelastForCancel,
  addStep,
  deleteStep,
  setIsEditing,
  setIsBeingAdded,
  setLoading,
  setSelectedStepType,
  setTabActiveKey,
} = voiceScenariosSlice.actions;

export default voiceScenariosSlice.reducer;

function createInitialState() {
  return {
    scenarios: [],
    speechToTexedStep: {},
    loading: false,
    failed: false,
    editing: false,
    isBeingAdded: false,
    selectedStepType: "",
    tabActiveKey: "steps",
    backgroundScenariosList: [],
  };
}
function createReducers() {
  return {
    addVoiceScenario: (state, action) => {
      state.scenarios.unshift(action.payload);
    },
    deleteLastForCancel: (state) => {
      state.scenarios.pop();
    },
    addStep: (state, action) => {
      let allScenarios = deepCopy(current(state.scenarios));
      let scenario = findObjectById(allScenarios, action.payload.scenarioID);
      const newStep = {
        id: "New Step",
        stepId: "",
        type: null,
        inputField: null,
        text: null,
        index: null,
        wait: null,
        maxLevenshteinDistance: null,
        errorExecutionTime: null,
        warningExecutionTime: null,
      };
      let { steps } = scenario;

      steps.splice(action.payload.index + 1, 0, newStep);

      scenario.steps = steps;

      state.scenarios = updateElementById(
        allScenarios,
        action.payload.scenarioID,
        scenario
      );
    },
    deleteStep: (state, action) => {
      let allScenarios = deepCopy(current(state.scenarios));
      let scenario = allScenarios.filter(
        (e) => e.id === action.payload.scenarioID
      )[0];
      let steps = scenario.steps.filter((e) => e.id !== action.payload.stepID);
      scenario.steps = steps;

      state.scenarios.splice(
        state.scenarios.findIndex((e) => e.id === action.payload.scenarioID),
        1,
        scenario
      );
    },
    setIsEditing: (state, action) => {
      state.editing = action.payload;
    },
    setIsBeingAdded: (state, action) => {
      state.isBeingAdded = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSelectedStepType: (state, action) => {
      state.selectedStepType = action.payload;
    },
    setTabActiveKey: (state, action) => {
      state.tabActiveKey = action.payload;
    },
  };
}
function createExtraReducers() {
  return {
    ...getAll(),
    ...create(),
    ...getOne(),
    ...edit(),
    ..._delete(),
    ..._getAllBackgroundScenarios(),
  };

  function getAll() {
    const { pending, fulfilled, rejected } = getAllVoiceScenarios;
    return {
      [pending]: (state) => {
        state.loading = true;
        state.failed = false;
      },
      [fulfilled]: (state, action) => {
        state.scenarios = action.payload;
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.rejected = true;
      },
    };
  }

  function create() {
    const { pending, fulfilled, rejected } = addNewVoiceScenario;
    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state) => {
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }

  function getOne() {
    const { pending, fulfilled, rejected } = getOneVoiceScenarioById;

    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state, action) => {
        state.loading = false;
        const currentState = current(state);
        const index = currentState.scenarios.findIndex(
          (scenario) => scenario.id === action.payload.id
        );
        index > -1
          ? state.scenarios.splice(index, 1, action.payload)
          : state.scenarios.push(action.payload);
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }

  function edit() {
    const { pending, fulfilled, rejected } = editVoiceScenario;

    return {
      [pending]: (state) => {
        state.loading = true;
      },
      [fulfilled]: (state) => {
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }
  function _delete() {
    const { pending, fulfilled, rejected } = deleteVoiceScenarioById;
    return {
      [pending]: (state) => {
        state.loading = true;
        state.failed = false;
      },
      [fulfilled]: (state) => {
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }
  function _getAllBackgroundScenarios() {
    const { pending, fulfilled, rejected } = getAllBackgroundScenarios;
    return {
      [pending]: (state) => {
        state.loading = true;
        state.failed = false;
      },
      [fulfilled]: (state, action) => {
        state.loading = false;
        console.log(action.payload);
        state.backgroundScenariosList = action.payload;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.failed = true;
      },
    };
  }
}
