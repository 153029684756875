import { ClockCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { extractTimeFromDate } from "utils";
import { getColumnStatus, getColumnType } from "utils/columnsUtils";

export const getPerformedTestDetailStepsColumns = () => {
  return [
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "8%",
      render: (stringDate) => {
        return (
          <>
            <div style={{ textAlign: "center" }}>
              <ClockCircleOutlined />
            </div>
            <div style={{ textAlign: "center" }}>
              {extractTimeFromDate(stringDate)}
            </div>
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "6%",
      render: getColumnType,
    },
    {
      title: "StepId",
      dataIndex: "stepId",
      key: "stepId",
      width: "6%",
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
      width: "50%",
      render: (text, record) => {
        switch (record.type) {
          case "INBOUND" || "LEAP":
            return <div style={{ textAlign: "left" }}>{text}</div>;
          case "OUTBOUND":
            return <div style={{ textAlign: "right" }}>{text}</div>;
          default:
            return text;
        }
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "6%",
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: getColumnStatus,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",

      ellipsis: {
        showTitle: false,
      },
      render: (reason) => {
        return (
          <Tooltip placement="topLeft" title={reason}>
            {reason}
          </Tooltip>
        );
      },
    },
  ];
};
