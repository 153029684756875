import { Button } from "antd";
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  FileImageOutlined,
  StopOutlined,
  UserSwitchOutlined
} from "@ant-design/icons";

const performedTestColumnsDefinition = [
  { title: "Performed Test ID", dataIndex: "id", key: "id" },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => {
      if (a && b && a.status && b.status) {
        return b.status.localeCompare(a.status);
      }
    },
    filters: [
      {
        text: "Success",
        value: "OK",
      },
      {
        text: "Error",
        value: "ERROR",
      },
    ],
    onFilter: (value, record) => {
      if (record && record.status) {
        return record.status.indexOf(value) === 0;
      }
    },
    render: (value) => {
      switch (value) {
        case "ERROR":
          return (
            <div style={{ textAlign: "center" }}>
              <ExclamationCircleFilled
                style={{ color: "#dc4c64", fontSize: "16px" }}
              />
            </div>
          );

        case "OK":
          return (
            <div style={{ textAlign: "center" }}>
              <CheckCircleFilled
                style={{ color: "#14a44d", fontSize: "16px" }}
              />
            </div>
          );

        case "HANDOVER":
          return (
              <div style={{ textAlign: "center" }}>
                <UserSwitchOutlined
                    style={{ color: "#14a44d", fontSize: "16px" }}
                />
              </div>
          );

        case "NO_ANSWER":
          return (
              <div style={{ textAlign: "center" }}>
                <StopOutlined
                    style={{ color: "#dc4c64", fontSize: "16px" }}
                />
              </div>
          );

        default:
          return <>Unknown Status Error: {value}</>;
      }
    },
  },
  {
    title: "Started at",
    dataIndex: "startedAt",
    key: "startedAt",
    sorter: (a, b) => {
      return Date.parse(b.startedAt) - Date.parse(a.startedAt);
    },
  },
  {
    title: "Finished at",
    dataIndex: "finishedAt",
    key: "finishedAt",
    sorter: (a, b) => {
      return Date.parse(b.finishedAt) - Date.parse(a.finishedAt);
    },
  },
  { title: "Chat ID", dataIndex: "chatId", key: "chatId" },
  {
    title: "Screenshot",
    dataIndex: "screenshot",
    key: "screenshot",
    render: (screenshot) => {
      if (screenshot != null) {
        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Button ghost>
                <a href={screenshot} target="_blank" rel="noreferrer">
                  <FileImageOutlined style={{ color: "#1890FF" }} />
                </a>
              </Button>
            </div>
          </>
        );
      }
    },
  },
  { title: "Reason", dataIndex: "reason", key: "reason" },
];

export { performedTestColumnsDefinition };
