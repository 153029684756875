import React from "react";
import PropTypes from "prop-types";
import StlTable from "components/shared/StlTable";

const MsisdnTable = ({ columns, dataSource, isLoading, editableCell }) => {
  return (
    <StlTable
      components={{
        body: {
          cell: editableCell,
        },
      }}
      dataSource={dataSource}
      columns={columns}
      loading={isLoading}
      pagination={{ showSizeChanger: true }}
    />
  );
};

MsisdnTable.propTypes = {
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default MsisdnTable;
