import { SCENARIO_API_ENDPOINT } from "utils";
import { fetchWrapper } from "utils/fetchWrapper";
export const SENTINEL_CONFIG_URL = SCENARIO_API_ENDPOINT + "config/";

const getAll = () => {
  return fetchWrapper.post(SENTINEL_CONFIG_URL + "search", { criteria: [] });
};
const getOne = (id) => {
  return fetchWrapper.get(SENTINEL_CONFIG_URL + id);
};
const create = (newScenario) => {
  return fetchWrapper.post(SENTINEL_CONFIG_URL, newScenario);
};
const update = (editedScenario) => {
  return fetchWrapper.post(SENTINEL_CONFIG_URL, editedScenario);
};
const remove = (id) => {
  return fetchWrapper.delete(SENTINEL_CONFIG_URL + id);
};

const ConfigurationService = {
  getAll,
  getOne,
  create,
  update,
  remove,
};

export default ConfigurationService;
