import React, { useState } from "react";
import { getInputNode } from "utils";
import StlFormItem from "components/shared/StlFormItem";

const options = {
  scenarioType: [
    {
      value: "STANDARD",
      label: "standard",
    },
    {
      value: "BACKGROUND",
      label: "background",
    },
  ],
  state: [
    {
      value: "ENABLED",
      label: "Enabled",
    },
    {
      value: "DISABLED",
      label: "Disabled",
    },
  ],
  userType: [
    {
      value: "consumer",
      label: "consumer",
    },
    {
      value: "business",
      label: "business",
    },
    {
      value: "next_consumer",
      label: "next_consumer",
    },
    {
      value: "simple_business",
      label: "simple_business",
    },
    {
      value: "simple_consumer",
      label: "simple_consumer",
    },
  ],
};
export const VoiceScenarioDescriptionEditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const [value, setValue] = useState(record?.state);

  let inputNode = getInputNode(inputType, dataIndex, value, setValue, options);

  const checkCustomTitles = () => {
    const customTitles = [
      "maxLevenshteinDistance",
      "errorTime",
      "warningTime",
    ].includes(dataIndex);
    return `Please Input ${customTitles ? dataIndex : title}!`;
  };
  const rules = [{ required: true, message: checkCustomTitles() }];

  return (
    <td {...restProps} key={index + "h"}>
      {editing ? (
        <div>
          <StlFormItem dataIndex={dataIndex} title={title} rules={rules}>
            {inputNode}
          </StlFormItem>
        </div>
      ) : (
        children
      )}
    </td>
  );
};

export default VoiceScenarioDescriptionEditableCell;
