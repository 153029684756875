import { createSlice } from "@reduxjs/toolkit";
import { getPerformedTestsByIdScenario } from "./voicePerformedTestThunk";

const name = "voicePerformedTestSlice";
const initialState = createInitialState();
const reducers = createReducers();
const extraReducers = createExtraReducers();

export const voicePerformedTestSlice = createSlice({
  name,
  initialState,
  reducers,
  extraReducers,
});

export const { setLoading, setPerformedTestItemSelected } =
  voicePerformedTestSlice.actions;

export default voicePerformedTestSlice.reducer;

function createInitialState() {
  return {
    performedTests: [],
    performedTestItemSelected: null,
    loading: false,
    failed: false,
  };
}
function createReducers() {
  return {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPerformedTestItemSelected: (state, action) => {
      state.performedTestItemSelected = action.payload;
    },
  };
}
function createExtraReducers() {
  return {
    ...getAll(),
  };

  function getAll() {
    const { pending, fulfilled, rejected } = getPerformedTestsByIdScenario;
    return {
      [pending]: (state) => {
        state.loading = true;
        state.failed = false;
      },
      [fulfilled]: (state, action) => {
        state.performedTests = action.payload;
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.rejected = true;
      },
    };
  }
}
