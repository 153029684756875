import React from "react";
import StlButton from "components/shared/StlButton";
import { Auth } from "@aws-amplify/auth";
import { setUserEmail } from "features/slices/user/personalInfoSlice";
import styles from "./LogoutButton.module.scss";

const LogoutButton = ({ ...props }) => {
  async function handleSignOut(event) {
    event.preventDefault();
    try {
       await Auth.signOut({
        redirect_uri: process.env.REACT_APP_CLOUDFRONT_DISTRIBUTION,
      });
      // await Auth.signOut();
      // setUserEmail(null);
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  //return <button onClick={handleSignOut}>Sign Out</button>;
  return (
    <StlButton
      className={styles.LogoutButton}
      {...props}
      buttonType="link"
      onClickHandler={handleSignOut}
      ghost={false}
    />
  );
};

export default LogoutButton;
