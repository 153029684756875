import { createAsyncThunk } from "@reduxjs/toolkit";
import ConfigurationService from "services/ConfigurationService";
import { SCENARIO_API_ENDPOINT } from "utils";
export const SENTINEL_CONFIG_URL = SCENARIO_API_ENDPOINT + "config/";

const name = "sentinelConfigurationSlice";
export const getAllConfigurations = createAsyncThunk(
  `${name}/getAllConfigurations`,
  () => {
    return ConfigurationService.getAll();
  }
);

export const deleteConfigurationsById = createAsyncThunk(
  `${name}/deleteConfigurationsById`,
  (id) => {
    return ConfigurationService.remove(id);
  }
);

export const addNewConfiguration = createAsyncThunk(
  `${name}/addNewConfiguration`,
  (newSentinelConfig) => {
    return ConfigurationService.create(newSentinelConfig);
  }
);

export const editConfiguration = createAsyncThunk(
  `${name}/editConfiguration`,
  (editedSentinelConfiguration) => {
    return ConfigurationService.update(editedSentinelConfiguration);
  }
);

export const getConfigurationById = createAsyncThunk(
  `${name}/getConfigurationById`,
  (id) => {
    return ConfigurationService.getOne(id);
  }
);
