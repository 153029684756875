import StlFrame from "components/shared/StlFrame";
import { setActivePage } from "features/slices/menu/menuSlice";
import {
  setIsBeingAdded,
  setTabActiveKey,
} from "features/slices/display/displayScenariosSlice";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DisplayScheduling from "components/modules/displayArea/displayScheduling/DisplayScheduling";
import DisplaySteps from "components/modules/displayArea/displaySteps/DisplaySteps";
import DisplayPerformedTests from "components/modules/displayArea/displayPerformedTests/DisplayPerformedTests";
import DisplayScenarioDescription from "components/modules/displayArea/displayScenarioDescription/DisplayScenarioDescription";
import StlAreaContainer from "components/shared/StlAreaContainer/StlAreaContainer";

export const DisplayDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isBeingAdded = useSelector(
    (store) => store.displayScenarios.isBeingAdded
  );
  const isEditing = useSelector((store) => store.displayScenarios.editing);
  const tabActiveKey = useSelector(
    (store) => store.displayScenarios.tabActiveKey
  );

  const tabsContents = {
    tabs: [
      { key: "steps", label: "Steps", children: <DisplaySteps /> },
      {
        key: "scheduling",
        label: "Scheduling",
        children: <DisplayScheduling />,
      },
      {
        key: "performed",
        label: "Performed",
        children: <DisplayPerformedTests />,
      },
    ],
    activeKey: tabActiveKey,
    onChangeHandler: (newActiveKey) => {
      dispatch(setTabActiveKey(newActiveKey));
    },
  };

  dispatch(setActivePage("display"));

  const backButtonHandler = () => {
    navigate("/");
    dispatch(setIsBeingAdded(false));
  };

  return (
    <StlFrame>
      <StlAreaContainer
        title="Display Scenario"
        tabContents={tabsContents}
        backButtonHandler={backButtonHandler}
        scenarioDescription={<DisplayScenarioDescription />}
        isEditing={isEditing}
        isBeingAdded={isBeingAdded}
      />
    </StlFrame>
  );
};
