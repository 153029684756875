import React from "react";
import StlFrame from "components/shared/StlFrame";
import MsisdnArea from "components/modules/msisdnArea/MsisdnArea";

const MsisdnPage = () => {
  return (
    <StlFrame>
      <MsisdnArea />
    </StlFrame>
  );
};

export default MsisdnPage;
