import StlFormItem from "components/shared/StlFormItem";
import { useState } from "react";
import { getInputNode } from "utils";

const emailRules = [
  {
    required: true,
    type: "email",
    message: "The input is not valid E-mail!",
  },
];

const options = {
  client: [
    {
      value: "display",
      label: "Display",
    },
    {
      value: "voice",
      label: "Voice",
    },
  ],
  enabled: [
    {
      value: true,
      label: "Enabled",
    },
    {
      value: false,
      label: "Disabled",
    },
  ],
};

const MsisdnEditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const [value, setValue] = useState("");
  let inputNode = getInputNode(inputType, dataIndex, value, setValue, options);

  const fieldWithoutValidation = [
    "username",
    "password",
    "puk",
    "serialNumber",
  ];

  let rules =
    dataIndex !== "username"
      ? [
          {
            required: true,
          },
        ]
      : emailRules;

  if (fieldWithoutValidation.includes(dataIndex)) {
    rules = [];
  }
  return (
    <td {...restProps}>
      {editing ? (
        <StlFormItem
          dataIndex={dataIndex}
          styles={{
            margin: 0,
          }}
          rules={rules}
        >
          {inputNode}
        </StlFormItem>
      ) : (
        children
      )}
    </td>
  );
};

export default MsisdnEditableCell;
