import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Radio, Card } from "antd";

import { useParams } from "react-router-dom";
import EnrichmentInfo from "../enrichmentInfo";
import EditableCell from "../editableCell";
import AlertMessage from "../../../shared/AlertMessage";
import { editDisplayScenarioData } from "../displayAreaActions/DisplayAreaActions";
import DisplayScenarioBaseDataActions from "../displayScenarioBaseDataActions";
import {
  SCENARIO_API_ENDPOINT,
  DISPLAY_API_ENDPOINT,
  deepCopy,
  openNotificationWithIcon,
} from "utils";
import {
  setIsBeingAdded,
  setIsEditing,
  setLoading,
  setTabActiveKey,
  setWarningBiggerThanError,
} from "features/slices/display/displayScenariosSlice";
import {
  editDisplayScenario,
  getDisplayScenarioById,
} from "features/slices/display/displayScenariosThunk";
import StlTable from "components/shared/StlTable";
import { fetchWrapper } from "utils/fetchWrapper";

import { displayScenarioDescriptionColumns } from "./DisplayScenarioDescriptionColumns";
import { getPerformedTestsByIdScenario } from "features/slices/voice/voicePerformedTest/voicePerformedTestThunk";

const DisplayScenarioDescription = (props) => {
  const params = useParams();
  const dispatch = useDispatch();

  const scenarios = useSelector((store) =>
    store.displayScenarios.scenarios.filter((e) => e.id === params.scenarioID)
  );
  const loading = useSelector((store) => store.displayScenarios.loading);
  const failed = useSelector((store) => store.displayScenarios.failed);
  const isEditing = useSelector((store) => store.displayScenarios.editing);
  const isBeingAdded = useSelector(
    (store) => store.displayScenarios.isBeingAdded
  );

  const [form] = Form.useForm();
  const [enrichmentType, setEnrichmentType] = useState();

  const onEditButtonClicked = (record) => {
    editDisplayScenarioData({
      record: record,
      enableEditing: () => dispatch(setIsEditing(true)),
      form: form,
    });
  };

  const onExecuteButtonClicked = (record) => {
    const scenario = { ...record };

    dispatch(setLoading(true));
    fetchWrapper
      .post(DISPLAY_API_ENDPOINT + "display/run/" + scenario.id)
      .then(() => {
        dispatch(setLoading(false));
        openNotificationWithIcon("success", "Esecuzione avviata");
        dispatch(setTabActiveKey("performed"));
        dispatch(getPerformedTestsByIdScenario(scenario.id));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        openNotificationWithIcon("error", error);
      });
  };

  const onCancelButtonClicked = () => dispatch(setIsEditing(false));

  const onSaveButtonClicked = async () => {
    const newEntry = await form.validateFields();
    newEntry.enrichmentType = enrichmentType;

    if (newEntry.warningExecutionTime >= newEntry.errorExecutionTime) {
      dispatch(setWarningBiggerThanError(true));
    } else {
      dispatch(setWarningBiggerThanError(false));
      const row = deepCopy(scenarios[0]);
      try {
        dispatch(setLoading(true));
        const theScenario = await fetch(
          SCENARIO_API_ENDPOINT + "display/scenario/" + row.id
        )
          .then((res) => (res.ok ? res : null))
          .then((scenario) => scenario.json());

        const editedScenario = { ...theScenario, ...newEntry };
        await dispatch(editDisplayScenario(editedScenario));
        await dispatch(getDisplayScenarioById(params.scenarioID));
      } catch {
        console.log("Failed to connect to server.");
      }

      dispatch(setIsBeingAdded(false));
      dispatch(setIsEditing(false));
    }
  };

  const displayColumnsConfig = {
    isEditing,
  };
  const columns = displayScenarioDescriptionColumns(displayColumnsConfig);

  const prepareScenario = (scenariosData) => {
    const copiedScenarios = deepCopy(scenariosData);
    copiedScenarios.forEach((e) => (e.key = e.id));
    return copiedScenarios;
  };

  const scenario = prepareScenario(scenarios);
  const scenarioData = scenario[0];

  useEffect(() => {
    dispatch(getDisplayScenarioById(params.scenarioID));
  }, [dispatch, params.scenarioID]);

  useEffect(() => {
    if (!isEditing && scenarios.length > 0) {
      setEnrichmentType(scenarios[0].enrichmentType);
    }
  }, [scenarios, isEditing]);

  const isEnrichmentEnabled =
    enrichmentType != null && enrichmentType !== "DISABLED";

  const enrichmentTypeRadioGroup = (
    <>
      <Radio.Group
        defaultValue={enrichmentType}
        value={enrichmentType}
        size="large"
        onChange={(data) => setEnrichmentType(data.target.value)}
        disabled={!isEditing}
      >
        <Radio.Button value="DISABLED">Disabled</Radio.Button>
        <Radio.Button value="CONSUMER">Consumer</Radio.Button>
        <Radio.Button value="BUSINESS">Business</Radio.Button>
      </Radio.Group>
    </>
  );

  return (
    <Form form={form}>
      {failed && (
        <div>
          <AlertMessage message="Failed" />
        </div>
      )}

      {scenarios.length > 0 ? (
        <>
          <Card
            title="Scenario Data"
            extra={
              <DisplayScenarioBaseDataActions
                isEditing={isEditing}
                isBeingAdded={isBeingAdded}
                recordData={scenarioData}
                onSave={onSaveButtonClicked}
                onEdit={onEditButtonClicked}
                onCancel={onCancelButtonClicked}
                onExecute={onExecuteButtonClicked}
              />
            }
          >
            <Card.Meta title="ID" description={scenarioData.id} />
            <Card.Meta
              title="Creation Date"
              description={scenarioData.createdAt}
              style={{ marginTop: 10, marginBottom: 30 }}
            />
            <StlTable
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              dataSource={scenario}
              columns={columns}
              loading={loading}
            />

            <Card.Meta
              title="Enrichment String"
              description={enrichmentTypeRadioGroup}
              style={{ marginTop: 20 }}
            />

            {isEnrichmentEnabled && (
              <EnrichmentInfo
                isEditing={isEditing}
                enrichmentData={scenario[0]}
                enrichmentType={enrichmentType}
                isLoading={loading}
                style={{ marginTop: 20 }}
              />
            )}
          </Card>
        </>
      ) : (
        <h2 style={{ height: "100px" }}>Scenario not found</h2>
      )}
    </Form>
  );
};

export default DisplayScenarioDescription;
