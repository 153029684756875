import { createSlice } from "@reduxjs/toolkit";
import { deepCopy, removeObjectById } from "utils";
import { addMsisdn, deleteMsisdn, findAllMsisdn } from "./msisdnThunk";

const name = "msisdnSlice";
const initialState = createInitialState();
const reducers = createReducers();
const extraReducers = createExtraReducer();

export const msisdnSlice = createSlice({
  name: name,
  initialState,
  reducers,
  extraReducers,
});

export const {
  setLoading,
  setEditing,
  setEditingKey,
  setIsAddingMsisdn,
  createMsisdn,
  removeNewMsisdn,
} = msisdnSlice.actions;

export default msisdnSlice.reducer;

function createInitialState() {
  return {
    isAddingMsisdn: false,
    loading: false,
    editing: false,
    editingKey: "",
    failed: false,
    rejected: false,
    msisdnList: [],
  };
}

function createReducers() {
  return {
    createMsisdn: (state, action) => {
      state.msisdnList.unshift(action.payload.newMsisdn);
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setEditing: (state, action) => {
      state.editing = action.payload;
    },
    setEditingKey: (state, action) => {
      state.editingKey = action.payload;
    },
    setIsAddingMsisdn: (state, action) => {
      state.isAddingMsisdn = action.payload;
    },
    removeNewMsisdn: (state, action) => {
      const msisdnListCopy = deepCopy(state.msisdnList);
      state.msisdnList = removeObjectById(msisdnListCopy, null);
    },
  };
}

function createExtraReducer() {
  return {
    ..._getAll(),
    ..._addMsisdn(),
    ..._deleteMsisdn(),
  };

  function _getAll() {
    const { pending, fulfilled, rejected } = findAllMsisdn;
    return {
      [pending]: (state) => {
        state.loading = true;
        state.failed = false;
      },
      [fulfilled]: (state, action) => {
        state.msisdnList = action.payload;
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.rejected = true;
      },
    };
  }

  function _addMsisdn() {
    const { pending, fulfilled, rejected } = addMsisdn;
    return {
      [pending]: (state) => {
        state.loading = true;
        state.failed = false;
      },
      [fulfilled]: (state, action) => {
        state.msisdnList.push(action.payload);
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.rejected = true;
      },
    };
  }
  function _deleteMsisdn() {
    const { pending, fulfilled, rejected } = deleteMsisdn;
    return {
      [pending]: (state) => {
        state.loading = true;
        state.failed = false;
      },
      [fulfilled]: (state, action) => {
        const msisdnListCopy = deepCopy(state.msisdnList);
        state.msisdnList = removeObjectById(msisdnListCopy, action.meta.arg.id);
      },
      [rejected]: (state) => {
        state.loading = false;
        state.rejected = true;
      },
    };
  }
}
