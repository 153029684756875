import { createAsyncThunk } from "@reduxjs/toolkit";
import MsisdnService from "services/MsisdnService";

const name = "msisdnSlice";

export const addMsisdn = createAsyncThunk(`${name}/create`, ({ msisdn }) => {
  return MsisdnService.create(msisdn);
});

export const findAllMsisdn = createAsyncThunk(`${name}/findAll`, () => {
  return MsisdnService.getAll();
});

export const deleteMsisdn = createAsyncThunk(`${name}/delete`, ({ id }) => {
  return MsisdnService.remove(id);
});
export const updateMsisdn = createAsyncThunk(`${name}/edit`, (editedMsisdn) => {
  return MsisdnService.update(editedMsisdn);
});
