import { createSlice } from "@reduxjs/toolkit";
import {
  getActions,
  getPerformedTestsDetailById,
} from "./voicePerformedTestDetailThunk";

const name = "voicePerformedTestDetailSlice";
const initialState = createInitialState();
const reducers = createReducers();
const extraReducers = createExtraReducers();

export const voicePerformedTestDetailSlice = createSlice({
  name,
  initialState,
  reducers,
  extraReducers,
});

export const { setLoading, setCheckAction } =
  voicePerformedTestDetailSlice.actions;

export default voicePerformedTestDetailSlice.reducer;

function createInitialState() {
  return {
    performedTestsDetail: null,
    actions: null,
    checkAction: null,
    loading: false,
    failed: false,
  };
}
function createReducers() {
  return {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCheckAction: (state, action) => {
      return { ...state, ...action.payload };
    },
  };
}
function createExtraReducers() {
  return {
    ..._getPerformedTestsDetailById(),
    ..._getActions(),
  };

  function _getPerformedTestsDetailById() {
    const { pending, fulfilled, rejected } = getPerformedTestsDetailById;
    return {
      [pending]: (state) => {
        state.loading = true;
        state.failed = false;
      },
      [fulfilled]: (state, action) => {
        state.performedTestsDetail = action.payload;
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.rejected = true;
      },
    };
  }
  function _getActions() {
    const { pending, fulfilled, rejected } = getActions;
    return {
      [pending]: (state) => {
        state.loading = true;
        state.failed = false;
      },
      [fulfilled]: (state, action) => {
        state.actions = action.payload;
        state.loading = false;
      },
      [rejected]: (state) => {
        state.loading = false;
        state.rejected = true;
      },
    };
  }
}
