import { Footer } from "antd/lib/layout/layout";
import React from "react";
import packageJson from "../../../../package.json";

const StlFooter = (props) => {
  const footerStyling = {
    backgroundColor: "#515151",
    color: "#FFF",
    textAlign: "center",
  };

  return (
    <Footer style={footerStyling}>
      TOBi Sentinel Configurator - v{packageJson.version}
    </Footer>
  );
};

export default StlFooter;
