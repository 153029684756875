import { 
    enrichmentConsumerChannelValues,
    enrichmentConsumerTypeValues, 
    enrichmentConsumerForceValues,
    enrichmentBusinessChannelValues,
    enrichmentBusinessProfileValues, 
    enrichmentBusinessSegmentValues
} from "./EnrichmentInfoValues";

const enrichmentConsumerColumnsDefinition = [
    { 
        index: 1, 
        title: 'Consumer Channel', 
        dataIndex: 'consumerChannel', 
        key: 'consumerChannel', 
        inputType: 'select',
        inputValues: enrichmentConsumerChannelValues 
    },
    { 
        index: 2, 
        title: 'Consumer Number', 
        dataIndex: 'consumerNumber', 
        key: 'consumerNumber', 
        inputType: 'text' 
    },
    { 
        index: 3, 
        title: 'Consumer Type', 
        dataIndex: 'consumerType', 
        key: 'consumerType', 
        inputType: 'select',
        inputValues: enrichmentConsumerTypeValues
    },
    { 
        index: 4, 
        title: 'Consumer Force', 
        dataIndex: 'consumerForce', 
        key: 'consumerForce', 
        inputType: 'select',
        inputValues: enrichmentConsumerForceValues
    },
    { 
        index: 5, 
        title: 'Consumer S', 
        dataIndex: 'consumerS', 
        key: 'consumerS', 
        inputType: 'text'
    },
]


const enrichmentBusinessColumnsDefinition = [
    { 
        index: 1, 
        title: 'Business Channel', 
        dataIndex: 'businessChannel', 
        key: 'businessChannel', 
        inputType: 'select',
        inputValues: enrichmentBusinessChannelValues 
    },
    { 
        index: 2, 
        title: 'Business Number', 
        dataIndex: 'businessNumber', 
        key: 'businessNumber', 
        inputType: 'text'
    },
    { 
        index: 3, 
        title: 'Business Username', 
        dataIndex: 'businessUsername', 
        key: 'businessUsername', 
        inputType: 'text'
    },
    { 
        index: 4, 
        title: 'Business Customer Code', 
        dataIndex: 'businessCustomerCode', 
        key: 'businessCustomerCode', 
        inputType: 'text'
    },
    { 
        index: 5, 
        title: 'Business Profile', 
        dataIndex: 'businessProfile', 
        key: 'businessProfile', 
        inputType: 'select',
        inputValues: enrichmentBusinessProfileValues
    },
    { 
        index: 6, 
        title: 'Business Segment', 
        dataIndex: 'businessSegment', 
        key: 'businessSegment', 
        inputType: 'select',
        inputValues: enrichmentBusinessSegmentValues
    },
    { 
        index: 7, 
        title: 'Business S', 
        dataIndex: 'businessS', 
        key: 'businessS', 
        inputType: 'text'
    },
]

export { 
    enrichmentConsumerColumnsDefinition, 
    enrichmentBusinessColumnsDefinition 
}
