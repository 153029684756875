import StlButton from "components/shared/StlButton";
import { readableDate } from "utils";
import { getColumnStatus } from "utils/columnsUtils";

export const getPerformedTestColumns = (
  showModal,
  selectedFilters,
  sortedInfo
) => {
  return [
    { title: "Performed Test ID", dataIndex: "id", key: "id" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => {
        return b.status.localeCompare(a.status);
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "status" && sortedInfo.order
        : null,

      filters: [
        {
          text: "Success",
          value: "OK",
        },
        {
          text: "Error",
          value: "ERROR",
        },
        {
          text: "Pending",
          value: "WAITING",
        },
        {
          text: "Empty",
          value: null,
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filteredValue: selectedFilters ? selectedFilters.status : null,
      render: getColumnStatus,
    },
    {
      title: "Check",
      dataIndex: "check",
      key: "check",
      width: "6%",
    },
    {
      title: "Creation Time",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => {
        return Date.parse(b.createdAt) - Date.parse(a.createdAt);
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "createdAt" && sortedInfo.order
        : null,
      render: (stringDate) => readableDate(stringDate),
    },
    {
      title: "Finishing Time",
      dataIndex: "finishedAt",
      key: "finishedAt",
      sorter: (a, b) => {
        return Date.parse(b.finishedAt) - Date.parse(a.finishedAt);
      },
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "finishedAt" && sortedInfo.order
        : null,
      render: (stringDate) => readableDate(stringDate),
    },

    { title: "Reason", dataIndex: "reason", key: "reason" },
    {
      title: "Detail",
      key: "detail",
      render: (record) => {
        return (
          <StlButton
            iconName="zoomIn"
            onClickHandler={() => {
              showModal(record);
            }}
          />
        );
      },
    },
  ];
};
