import { Popconfirm, Space } from "antd";
import StlButton from "components/shared/StlButton";
import StlFormItem from "components/shared/StlFormItem";
import StlSelectBox from "components/shared/StlSelectBox";
import { deepCopy } from "utils";

const editableFieldsByType = {
  INBOUND: [
    "text",
    "maxLevenshteinDistance",
    "errorTime",
    "warningTime",
    "stepId",
  ],
  OUTBOUND: [
    "text",
    "maxLevenshteinDistance",
    "errorTime",
    "warningTime",
    "stepId",
  ],
  BACKGROUND: ["text", "stepId"],
  START: ["stepId"],
  END: ["stepId"],
};

const prepareColumns = (columnsRaw, editingKey, stepType) => {
  const cols = deepCopy(columnsRaw);
  cols.forEach((e) => {
    const editableFields = editableFieldsByType[stepType] || [];

    if (editableFields.includes(e.dataIndex)) {
      e.editable = true;
    }
  });

  const mergedColumns = cols.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: record.key === editingKey,
      }),
    };
  });

  return mergedColumns;
};

export const getVoiceStepsColumns = (
  editingKey,
  onSelectTypeHandler,
  stepType,
  areMultipleSelected,
  isBeingAdded,
  addNewRow,
  edit,
  save,
  deleteRow,
  cancel,
  buttonStyle,
  handleMouseEnter,
  handleMouseLeave
) => {
  const stepTypes = [
    { value: "INBOUND", label: "INBOUND" },
    { value: "OUTBOUND", label: "OUTBOUND" },
    { value: "BACKGROUND", label: "BACKGROUND" },
  ];
  const stepsColumns = [
    // { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: "Step ID", dataIndex: "stepId", key: "stepId" },
    {
      title: "Type",
      dataIndex: "type",
      inputType: "selectBox",
      key: "type",
      render: (_, record) => {
        if (record.type !== "START" && record.type !== "END") {
          return record.key === editingKey ? (
            <StlFormItem
              title="type"
              rules={[
                {
                  required: true,
                  message: "Type is required",
                },
              ]}
              noStyle
            >
              <StlSelectBox
                options={stepTypes}
                onChangeHandler={onSelectTypeHandler}
                placeholder={"Select Type"}
                defaultValue={record.type}
              />
            </StlFormItem>
          ) : (
            <div>{record.type}</div>
          );
        } else {
          return <div>{record.type}</div>;
        }
      },
    },
    {
      title: "Text",
      dataIndex: "text",
      inputType: stepType === "BACKGROUND" ? "selectBox" : "text",
      key: "text",
    },
    {
      title: "Max Levenshtein Distance",
      dataIndex: "maxLevenshteinDistance",
      key: "maxLevenshteinDistance",
      inputType: "number",
      width: "9%",
    },
    {
      title: "Error Time",
      dataIndex: "errorTime",
      key: "errorTime",
      inputType: "number",
    },
    {
      title: "Warning Time",
      dataIndex: "warningTime",
      key: "warningTime",
      inputType: "number",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      inputType: "actions",
      render: (_, record, ix) => {
        const disableConditions =
          editingKey !== "" || areMultipleSelected || isBeingAdded;
        return record.key === editingKey ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <StlButton
              iconName="save"
              onClickHandler={() => save(record)}
              buttonStyle={{ marginRight: 8 }}
              key={record.key + "saveButton"}
            />

            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => cancel(record.id)}
            >
              <StlButton
                iconName="cancel"
                buttonStyle={buttonStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                key={record.key + "cancelButton"}
              />
            </Popconfirm>
          </div>
        ) : (
          <Space>
            <StlButton
              iconName="edit"
              onClickHandler={() => edit(record)}
              disabled={disableConditions}
            />

            {record.type !== "START" ? (
              record.type !== "END" ? (
                <Popconfirm
                  title="sure to delete?"
                  onConfirm={() => deleteRow(record.id)}
                  disabled={disableConditions}
                >
                  <StlButton iconName="delete" disabled={disableConditions} />
                </Popconfirm>
              ) : null
            ) : null}
            {record.type !== "END" ? (
              <StlButton
                iconName="add"
                onClickHandler={() => addNewRow(ix)}
                disabled={disableConditions}
              />
            ) : null}
          </Space>
        );
      },
    },
  ];

  return prepareColumns(stepsColumns, editingKey, stepType);
};
