import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { performedTestColumnsDefinition } from "./DisplayPerformedTestsColumns";

import AlertMessage from "components/shared/AlertMessage";
import StlTable from "components/shared/StlTable";
import { getPerformedTestsByIdScenario } from "./DisplayPerformedTestsService";

const DisplayPerformedTests = () => {
  const [performedTestsData, setPerformedTestsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const params = useParams();

  const preparePerformedTests = (data) => {
    let copiedPerformedTestsRaw = JSON.parse(JSON.stringify(data));
    copiedPerformedTestsRaw.forEach((e) => (e.key = e.id));
    return copiedPerformedTestsRaw;
  };

  useEffect(() => {
    let performedTests;
    setLoading(true);
    getPerformedTestsByIdScenario(params.scenarioID)
      .then((displayPerformedTests) => {
        performedTests = preparePerformedTests(displayPerformedTests);
        setPerformedTestsData(performedTests);
        setLoading(false);
      })
      .catch((err) => {
        setFailed(true);
        console.log(err);
      });
  }, [params.scenarioID]);

  /* 
  import { useState, useEffect } from 'react';

const useDataFetching = (getDataFn, ...args) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    let fetchedData;

    const fetchData = async () => {
      setLoading(true);

      try {
        fetchedData = await getDataFn(...args);
        setData(fetchedData);
        setLoading(false);
      } catch (error) {
        setFailed(true);
        console.log(error);
      }
    };

    fetchData();
  }, [getDataFn, ...args]);

  return { data, loading, failed };
}; */

  return (
    <>
      {failed && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            minHeight: "42px",
          }}
        >
          <AlertMessage message="Failed" />
        </div>
      )}
      <StlTable
        dataSource={performedTestsData}
        columns={performedTestColumnsDefinition}
        loading={loading}
        pagination={{ position: ["topLeft"] }}
      />
    </>
  );
};

export default DisplayPerformedTests;
