import React, { useState } from "react";
import PropTypes from "prop-types";

import { Divider, Input } from "antd";
import { ReCron, Tab } from "@sbzen/re-cron";
import "./StlCron.scss";

const StlCron = ({ value, onChangeHandle, tabs }) => {
  const [cronValue, setCronValue] = useState(value ? value : "0 0 0 ? * * *");

  return (
    <div className="Stl-cron">
      {<Input disabled value={cronValue} />}
      <Divider />
      <ReCron
        renderYearsFrom={new Date().getFullYear()}
        renderYearsTo={new Date().getFullYear() + 10}
        value={cronValue}
        onChange={(value) => {
          onChangeHandle(value);
          setCronValue(value);
        }}
        tabs={tabs}
      />
    </div>
  );
};

StlCron.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string),
  onChangeHandle: PropTypes.func,
};

StlCron.defaultProps = {
  tabs: [Tab.SECONDS, Tab.MINUTES, Tab.HOURS, Tab.DAY, Tab.MONTH, Tab.YEAR],
  onChangeHandle: () => {},
};

export default StlCron;
