
const enrichmentConsumerChannelValues = [ 
    {title: "web", value: "web", key: "web"}, 
    {title: "app", value: "app", key: "app"}, 
    {title: "msite", value: "msite", key: "msite"}
]

const enrichmentConsumerTypeValues = [ 
    {title: "CLI", value: "CLI", key: "CLI"}, 
    {title: "MSISDN", value: "MSISDN", key: "MSISDN"}
]

const enrichmentConsumerForceValues = [ 
    {title: "LOGIN", value: "LOGIN", key: "LOGIN"}, 
    {title: "ENRICHMENT", value: "ENRICHMENT", key: "ENRICHMENT"}
]

const enrichmentBusinessChannelValues = [ 
    {title: "web", value: "web", key: "web"}, 
    {title: "entapp", value: "entapp", key: "entapp"}, 
    {title: "msite", value: "msite", key: "msite"}
]

const enrichmentBusinessProfileValues = [ 
    {title: "UTENTE", value: "UTENTE", key: "UTENTE"}, 
    {title: "DIPENDENTE", value: "DIPENDENTE", key: "DIPENDENTE"}, 
    {title: "BASIC", value: "BASIC", key: "BASIC"}, 
    {title: "REFERENTE", value: "REFERENTE", key: "REFERENTE"}
]

const enrichmentBusinessSegmentValues = [
    {title: "soho", value: "soho", key: "soho"}, 
    {title: "corporate", value: "corporate", key: "corporate"}, 
    {title: "sme", value: "sme", key: "sme"}
]

export {
    enrichmentConsumerChannelValues,
    enrichmentConsumerTypeValues, 
    enrichmentConsumerForceValues,
    enrichmentBusinessChannelValues,
    enrichmentBusinessProfileValues, 
    enrichmentBusinessSegmentValues
}
