import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import StlFormItem from "components/shared/StlFormItem";

const ConfigurationEditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = ["toNumber"].includes(dataIndex) ? (
    <Input type="number" addonBefore="+" style={{ width: "170px" }} />
  ) : (
    //<TextareaAutosize autoFocus style={{ width: "100%" }} />
    <TextArea autoSize />
  );

  return (
    <td {...restProps} key={index + "h"}>
      {editing ? (
        <div>
          <StlFormItem title={title} dataIndex={dataIndex}>
            {inputNode}
          </StlFormItem>
        </div>
      ) : (
        children
      )}
    </td>
  );
};

export default ConfigurationEditableCell;
