import { createAsyncThunk } from "@reduxjs/toolkit";
import VoiceService from "services/VoiceService";
import { SCENARIO_API_ENDPOINT } from "utils";

const name = "voiceScenariosSlice";
export const VOICE_URL = SCENARIO_API_ENDPOINT + "voice/scenario/";
export const getAllVoiceScenarios = createAsyncThunk(
  `${name}/getAllVoiceScenarios`,
  () => {
    return VoiceService.getAll();
  }
);

export const deleteVoiceScenarioById = createAsyncThunk(
  `${name}/deleteVoiceScenarioById`,
  (id) => {
    return VoiceService.remove(id);
  }
);

export const addNewVoiceScenario = createAsyncThunk(
  `${name}/addNewVoiceScenario`,
  (newScenario) => {
    return VoiceService.create(newScenario);
  }
);

export const editVoiceScenario = createAsyncThunk(
  `${name}/editVoiceScenario`,
  (editedScenario) => {
    return VoiceService.update(editedScenario);
  }
);

export const getOneVoiceScenarioById = createAsyncThunk(
  `${name}/getOneVoiceScenarioById`,
  (id) => {
    return VoiceService.getOne(id);
  }
);
export const getAllBackgroundScenarios = createAsyncThunk(
  `${name}/getAllBackgroundScenarios`,
  () => {
    return VoiceService.getBackgroundScenarios();
  }
);
