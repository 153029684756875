async function editDisplayScenarioData(data) {
  data.enableEditing();

  data.form.setFieldsValue({
    number: "",
    text: "",
    ...data.record,
  });
}

export { editDisplayScenarioData };
