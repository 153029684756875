import { Form } from "antd";
import {
  removeNewMsisdn,
  setEditingKey,
  setIsAddingMsisdn,
} from "features/slices/msisdn/msisdnSlice";
import {
  addMsisdn,
  deleteMsisdn,
  findAllMsisdn,
} from "features/slices/msisdn/msisdnThunk";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findObjectById } from "utils";
import { addKeyProperty } from "utils/transform/transformUtils";
import { getMsisdnColumns } from "./MsisdnColumns";
import MsisdnEditableCell from "./MsisdnEditableCell";
import MsisdnTable from "./MsisdnTable";

const MsisdnList = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const msisdnListRaw = useSelector((store) => store.msisdn.msisdnList);
  const isLoading = useSelector((store) => store.msisdn.loading);
  const editingKey = useSelector((store) => store.msisdn.editingKey);

  const isEditing = (record) => record.key === editingKey;

  useEffect(() => {
    dispatch(findAllMsisdn());
  }, [dispatch]);

  const msisdnList = addKeyProperty(msisdnListRaw);

  const handleDelete = (record) => {
    if (record.id) {
      dispatch(deleteMsisdn({ id: record.id })).then(() => {
        dispatch(findAllMsisdn());
        dispatch(setIsAddingMsisdn(false));
      });
    } else {
      dispatch(removeNewMsisdn());
      dispatch(setIsAddingMsisdn(false));
    }
  };

  const handleEdit = (record) => {
    form.setFieldsValue({
      ...record,
    });

    dispatch(setEditingKey(record.key));
  };

  const handleSave = async (record) => {
    let updatedObject = null;
    const row = await form.validateFields();

    const saveMsisdn = (msisdn) => {
      dispatch(addMsisdn({ msisdn: msisdn })).then((msisdnResponse) => {
        dispatch(findAllMsisdn());
        dispatch(setEditingKey(""));
      });
    };

    if (!row.errorFields) {
      if (!record.id) {
        updatedObject = { ...row };
      } else {
        const foundObject = findObjectById(msisdnList, record.id);
        if (foundObject) {
          updatedObject = { ...foundObject, ...row };
        }
      }

      saveMsisdn(updatedObject);
      dispatch(setIsAddingMsisdn(false));
    }
  };

  const handleCancel = () => {
    dispatch(setEditingKey(""));
  };

  const columnsConfig = {
    handleDelete,
    handleEdit,
    handleSave,
    handleCancel,
    editingKey,
    isEditing,
  };

  const columns = getMsisdnColumns(columnsConfig);

  return (
    <Form form={form} component={false}>
      <MsisdnTable
        editableCell={MsisdnEditableCell}
        columns={columns}
        dataSource={msisdnList}
        isLoading={isLoading}
      />
    </Form>
  );
};
export default MsisdnList;
