import React from "react";
import PropTypes from "prop-types";
import Modal from "antd/lib/modal/Modal";

const StlModal = ({
  title,
  visible,
  width,
  children,
  onOkHandle,
  onCancelHandle,
  okText,
  maskClosable,
  destroyOnClose,
  cancelText,
  ...rest
}) => {
  return (
    <Modal
      title={title}
      open={visible}
      onOk={onOkHandle}
      onCancel={onCancelHandle}
      width={width}
      okText={okText}
      maskClosable={maskClosable}
      destroyOnClose={destroyOnClose}
      cancelText={cancelText}
      {...rest}
    >
      {children}
    </Modal>
  );
};

StlModal.propTypes = {
  title: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  visible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onOkHandle: PropTypes.func.isRequired,
  onCancelHandle: PropTypes.func.isRequired,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
};

StlModal.defaultProps = {
  visible: false,
  onOkHandle: () => {},
  onCancelHandle: () => {},
  width: 500,
  maskClosable: true,
  destroyOnClose: true,
};

export default StlModal;
