import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getDisplayScenarios } from "features/slices/display/displayScenariosThunk";

import { prepareScenarios } from "utils/transform/displayScenariosTransform";
import { getAllDisplaySchedules } from "features/slices/display/displaySchedulingsThunk";

const useDisplayScenarios = () => {
  const dispatch = useDispatch();

  const scenariosRaw = useSelector((store) =>
    store.displayScenarios.scenarios
      ? store.displayScenarios.scenarios
      : [{ id: null }]
  );

  const loading = useSelector((store) => store.displayScenarios.loading);
  const failed = useSelector((store) => store.displayScenarios.failed);

  useEffect(() => {
    dispatch(getDisplayScenarios());
    dispatch(getAllDisplaySchedules());
  }, [dispatch]);

  const scenarios = prepareScenarios(scenariosRaw);

  return { failed, scenarios, loading };
};

export default useDisplayScenarios;
