import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Header } from "antd/lib/layout/layout";
import tobiIcon from "../../../assets/ProfileTobi.svg";
import LogoutButton from "components/shared/loginButton/LogoutButton";
import StlUserInfo from "components/shared/StlUserInfo";
import StlMenu from "components/shared/StlMenu";
import styles from "./StlHeader.module.scss";
//import { useIsAuthenticated } from "@azure/msal-react";
import { setActivePage } from "features/slices/menu/menuSlice";
/* 
const removeCompanyName = (userName) => {
  if (userName) {
    const userNameSplit = userName.split(",");
    if (userNameSplit.length > 0) {
      return `${userNameSplit[0]}, ${userNameSplit[1]}`;
    } else {
      return `${userNameSplit[0]}`;
    }
  }
};
 */
const StlHeader = (children) => {
  const navigate = useNavigate();
  //const isAuthenticated = useIsAuthenticated();

  //const personalInfo = useSelector((store) => store.personalInfo);
  //const userName = removeCompanyName(personalInfo.name);
  const userName = useSelector((store) => store.personalInfo.userEmail);

  const dispatch = useDispatch();

  return (
    <Header className={styles.StlHeader}>
      <div className={styles.topSubHeader}>
        <div className={styles.AppName}>Tobi Sentinel Configurator</div>
        <div className={styles.logoutBox}>
          {userName && <span>{<LogoutButton> Logout </LogoutButton>}</span>}
        </div>
      </div>
      <div className={styles.bottomSubHeader}>
        <div className={styles.logo}>
          <img
            onClick={() => {
              navigate("/");
              dispatch(setActivePage("display"));
            }}
            src={tobiIcon}
            style={{ width: "40px", cursor: "pointer" }}
            alt="Sentinel configurator"
          />
        </div>
        <div className={styles.menuBox}>{userName && <StlMenu />}</div>

        <div className={styles.userInfoBox}>
          <div style={{ textAlign: "right" }}>
            {userName && <StlUserInfo user={userName} />}
          </div>
        </div>
      </div>
    </Header>
  );
};

export default StlHeader;
