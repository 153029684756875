import React from "react";
import EditableCell from "../editableCell";
import {
  enrichmentConsumerColumnsDefinition,
  enrichmentBusinessColumnsDefinition,
} from "./EnrichmentInfoColumns";
import StlTable from "components/shared/StlTable";

const EnrichmentInfo = ({
  enrichmentData,
  isEditing,
  isLoading,
  enrichmentType,
  style,
}) => {
  const preparedConsumerColumns = enrichmentConsumerColumnsDefinition.map(
    (col) => {
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.inputType,
          inputValues: col.inputValues,
          dataIndex: col.dataIndex,
          index: col.index,
          title: col.title,
          editing: isEditing,
        }),
      };
    }
  );

  const preparedBusinessColumns = enrichmentBusinessColumnsDefinition.map(
    (col) => {
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.inputType,
          inputValues: col.inputValues,
          dataIndex: col.dataIndex,
          index: col.index,
          title: col.title,
          editing: isEditing,
        }),
      };
    }
  );

  return (
    <>
      {enrichmentType === "CONSUMER" && (
        <StlTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          style={style}
          columns={preparedConsumerColumns}
          dataSource={[enrichmentData]}
          loading={isLoading}
        />
      )}
      {enrichmentType === "BUSINESS" && (
        <StlTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          style={style}
          columns={preparedBusinessColumns}
          dataSource={[enrichmentData]}
          loading={isLoading}
        />
      )}
    </>
  );
};

export default EnrichmentInfo;
