import { REPORT_API_ENDPOINT } from "utils";
import { fetchWrapper } from "utils/fetchWrapper";

export const getPerformedTestsByIdScenario = async (idScenario) => {
  return fetchWrapper
    .post(REPORT_API_ENDPOINT + "display/search", {
      criteria: [
        {
          field: "idScenario",
          operator: "eq",
          value: idScenario,
        },
      ],
    })
    .catch((error) => {
      console.log(error);
    });
};
