import React from "react";
import PropTypes from "prop-types";
import Layout, { Content } from "antd/lib/layout/layout";

import StlFooter from "components/shared/StlFooter";
import StlHeader from "components/shared/StlHeader";

function StlFrame({ children }) {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <StlHeader />
      <Content style={{ padding: "50px", color: "#f7f7f7" }}>
        {" "}
        {children}
      </Content>
      <StlFooter />
    </Layout>
  );
}

StlFrame.propTypes = {
  children: PropTypes.element.isRequired,
};

export default StlFrame;
