import { Input } from "antd";
import StlFormItem from "components/shared/StlFormItem";

const EditableCell = ({
  editingEnabled,
  index,
  dataIndex,
  title,
  children,
}) => (
  <td key={index + "h"}>
    {editingEnabled ? (
      <StlFormItem
        dataIndex={dataIndex}
        title={title}
        styles={{ display: "flex", alignItems: "center" }}
      >
        <Input />
      </StlFormItem>
    ) : (
      <span>{children}</span>
    )}
  </td>
);

export default EditableCell;
