import { SCENARIO_API_ENDPOINT } from "utils";
import { fetchWrapper } from "utils/fetchWrapper";
export const VOICE_URL = SCENARIO_API_ENDPOINT + "voice/scenario/";
export const DISPLAY_URL = SCENARIO_API_ENDPOINT + "display/scenario/";

const getAll = () => {
  return fetchWrapper.post(VOICE_URL + "search", { criteria: [] });
};
const getOne = (id) => {
  return fetchWrapper.get(VOICE_URL + id);
};
const create = (newScenario) => {
  return fetchWrapper.post(VOICE_URL, newScenario);
};
const update = (editedScenario) => {
  return fetchWrapper.post(VOICE_URL, editedScenario);
};
const remove = (id) => {
  return fetchWrapper.delete(VOICE_URL + id);
};

const getBackgroundScenarios = () => {
  return fetchWrapper.post(VOICE_URL + "search", {
    criteria: [
      {
        field: "scenarioType",
        operator: "eq",
        value: "BACKGROUND",
      },
    ],
  });
};

const VoiceService = {
  getAll,
  getOne,
  create,
  update,
  remove,
  getBackgroundScenarios,
};

export default VoiceService;
