import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createMsisdn,
  setIsAddingMsisdn,
} from "features/slices/msisdn/msisdnSlice";

import StlButton from "components/shared/StlButton";
import MsisdnList from "./msisdnList";

const MsisdnArea = () => {
  const dispatch = useDispatch();
  const isAddingMsisdn = useSelector((store) => store.msisdn.isAddingMsisdn);

  const addNew = () => {
    const newMsisdn = {
      id: null,
      msisdn: "",
      des: "",
      type: "",
      enabled: "",
      frequency: "",
      client: "",
      username: "",
      password: "",
      puk: null,
      serialNumber: null,
    };

    dispatch(createMsisdn({ newMsisdn }));
    dispatch(setIsAddingMsisdn(true));
  };

  useEffect(() => {
    return () => {
      dispatch(setIsAddingMsisdn(false));
    };
  }, [dispatch]);

  return (
    <div>
      <div style={{ textAlign: "right" }}>
        <StlButton
          buttonType="dashed"
          onClickHandler={addNew}
          buttonStyle={{
            marginBottom: "10px",
            color: "rgba(205, 84, 71, 0.8)",
          }}
          disabled={isAddingMsisdn}
          ghost={false}
          buttonShape="default"
        >
          Add Msisdn
        </StlButton>
      </div>
      <MsisdnList />
    </div>
  );
};

export default MsisdnArea;
