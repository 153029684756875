import React from "react";
import DisplayScenarios from "components/modules/displayArea/DisplayScenarios";
import StlFrame from "components/shared/StlFrame";

const DisplayPage = () => {
  return (
    <StlFrame>
      <DisplayScenarios />
    </StlFrame>
  );
};

export default DisplayPage;
