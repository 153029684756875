import React from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import { rest } from "lodash";

function StlFormItem({ children, dataIndex, title, rules, styles }) {
  const defaultConfig = {
    rules: [
      {
        required: true,
        message: `Please Input ${title}!`,
      },
    ],
    styles: {
      margin: 0,
    },
  };

  return (
    <Form.Item
      name={dataIndex}
      style={styles || defaultConfig.styles}
      rules={rules || defaultConfig.rules}
      {...rest}
    >
      {children}
    </Form.Item>
  );
}

StlFormItem.propTypes = {
  children: PropTypes.element,
  dataIndex: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rules: PropTypes.arrayOf(Object),
  styles: PropTypes.object,
};

export default StlFormItem;
