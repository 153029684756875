import { Input, InputNumber, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import StlSelectBox from "components/shared/StlSelectBox";
import _ from "lodash";
import moment from "moment";

export const getLocaleLanguage = () => {
  return window.navigator.userLanguage || window.navigator.language;
};

export const sortColumnFunc = (dataIndex) => {
  return (a, b) => {
    if (a[dataIndex] && b[dataIndex]) {
      if (a[dataIndex] < b[dataIndex]) return -1;
      if (a[dataIndex] > b[dataIndex]) return 1;
      return 0; //default return value (no sorting)
    }
  };
};

export const getInputNode = (
  inputType,
  dataIndex,
  value,
  setValue,
  options
) => {
  const _options = options && dataIndex ? options[dataIndex] : [];
  switch (inputType) {
    case "number":
      return <InputNumber />;
    case "fromNumber":
    case "toNumber":
      return <Input type="number" addonBefore="+" style={{ width: "170px" }} />;
    case "selectBox":
      const handleChange = (value) => {
        setValue(value);
      };

      return (
        <StlSelectBox
          defaultValue={value}
          placeholder={dataIndex}
          onChangeHandler={handleChange}
          options={_options}
        />
      );
    default:
      return <TextArea autoSize />;
  }
};

export const deepCopy = (data) => {
  return _.cloneDeep(data);
};

export const readableDate = (stringDate) => {
  const newFormatDate = new Date(stringDate);
  return moment(newFormatDate).format("ddd YYYY-MM-DD HH:mm:ss");
};

export const extractTimeFromDate = (stringDate) => {
  const newFormatDate = new Date(stringDate);
  return moment(newFormatDate).format("HH:mm:ss");
};

export const orderDescend = (items, field) => {
  if (!_.isArray(items)) {
    return "items type must to be an array of object";
  }
  return _.orderBy(items, [(item) => item[field]], ["desc"]);
};

export const orderAscend = (items, field) => {
  return _.orderBy(items, [(item) => item[field]], ["asc"]);
};

export const updateElementById = (array, id, updatedElement) => {
  if (!array || !Array.isArray(array) || !id || !updatedElement) {
    throw new Error(
      "Missing required Parameters : array, id, and updatedElement"
    );
  }

  return array.map((element) => {
    if (element.id === id) {
      return { ...element, ...updatedElement };
    }
    return element;
  });
};

export const findObjectById = (array = [], id) => {
  if (!array || !Array.isArray(array) || !id) {
    throw new Error("Missing required Parameters : array, and  id");
  }
  return array.find((element) => element.id === id);
};

export const removeObjectById = (arr, id) => {
  const index = arr.findIndex((obj) => obj.id === id);
  if (index !== -1) {
    arr.splice(index, 1);
    return arr;
  } else {
    throw new Error(`the obj with ID ${id} was not finded into the array.`);
  }
};

export const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: message,
  });
};
