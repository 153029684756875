import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsBeingAdded } from "features/slices/configuration/sentinelConfigurationSlice";
import StlFrame from "components/shared/StlFrame";
import SentinelConfigurationDescription from "components/modules/configurationArea/SentinelConfigurationDescription";
import StlAreaContainer from "components/shared/StlAreaContainer/StlAreaContainer";

export const ConfigurationDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isBeingAdded = useSelector(
    (store) => store.sentinelConfiguration.isBeingAdded
  );
  const isEditing = useSelector((store) => store.sentinelConfiguration.editing);

  const backButtonHandler = () => {
    navigate("/configuration");
    dispatch(setIsBeingAdded(false));
  };
  return (
    <StlFrame>
      <StlAreaContainer
        title="Configuration"
        tabContents={[]}
        backButtonHandler={backButtonHandler}
        scenarioDescription={<SentinelConfigurationDescription />}
        isEditing={isEditing}
        isBeingAdded={isBeingAdded}
      />
    </StlFrame>
  );
};
