import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StlFrame from "components/shared/StlFrame";
import {
  setIsBeingAdded,
  setTabActiveKey,
} from "features/slices/voice/voiceScenariosSlice";
import VoiceScenarioDescription from "components/modules/voiceArea/voiceScenarioDescription/VoiceScenarioDescription";
import VoiceSteps from "components/modules/voiceArea/voiceSteps/VoiceSteps";
import VOICEScheduling from "components/modules/voiceArea/voiceSchedules/VoiceScheduling";
import VoicePerformedTests from "components/modules/voiceArea/voicePerformedTests";
import StlAreaContainer from "components/shared/StlAreaContainer/StlAreaContainer";

export const VoiceDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tabActiveKey = useSelector(
    (store) => store.voiceScenarios.tabActiveKey
  );

  const tabsContents = {
    tabs: [
      {
        label: "Steps",
        key: "steps",
        children: <VoiceSteps />,
      },
      {
        label: "Scheduling",
        key: "scheduling",
        children: <VOICEScheduling />,
      },
      {
        label: "Performed",
        key: "performed",
        children: <VoicePerformedTests />,
      },
    ],
    activeKey: tabActiveKey,
    onChangeHandler: (newActiveKey) => {
      dispatch(setTabActiveKey(newActiveKey));
    },
  };

  const isBeingAdded = useSelector(
    (store) => store.voiceScenarios.isBeingAdded
  );
  const isEditing = useSelector((store) => store.voiceScenarios.editing);

  const backButtonHandler = () => {
    navigate("/voice");
    dispatch(setIsBeingAdded(false));
    dispatch(setTabActiveKey("steps"));
  };
  return (
    <StlFrame>
      <StlAreaContainer
        title="Voice Scenario"
        tabContents={tabsContents}
        backButtonHandler={backButtonHandler}
        scenarioDescription={<VoiceScenarioDescription />}
        isEditing={isEditing}
        isBeingAdded={isBeingAdded}
      />
    </StlFrame>
  );
};
