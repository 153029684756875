import React, { useState } from "react";
import { getInputNode } from "utils";
import StlFormItem from "components/shared/StlFormItem";
import { useSelector } from "react-redux";
import { getBackgroundScenariosList } from "utils/transform";

export const VoiceStepsEditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const backgroundScenariosList = useSelector(
    (store) => store.voiceScenarios.backgroundScenariosList
  );
  const options = {};
  options["text"] = getBackgroundScenariosList(backgroundScenariosList);
  //@todo set default value
  const [value, setValue] = useState(options?.text[0]?.value || "");

  let inputNode = getInputNode(inputType, dataIndex, value, setValue, options);

  let rules = [
    {
      required: true,
      message: `Please Input ${title}!`,
    },
  ];
  const notValidationFields = [
    "maxLevenshteinDistance",
    "errorTime",
    "warningTime",
  ];
  if (notValidationFields.includes(dataIndex)) {
    rules = [];
  }

  return (
    <td {...restProps} key={index + "h"}>
      {editing ? (
        <StlFormItem
          dataIndex={dataIndex}
          title={title}
          rules={rules}
          styles={{
            display: "flex",
            alignItems: "center",
            marginBottom: "0",
          }}
        >
          {inputNode}
        </StlFormItem>
      ) : (
        children
      )}
    </td>
  );
};
