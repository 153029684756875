import { Input } from "antd";
import StlFormItem from "components/shared/StlFormItem";
import { setIndexOrText } from "features/slices/display/displayScenariosSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTextareaAutosize from "react-textarea-autosize";
import { getInputNode } from "utils";
import { getBackgroundScenariosList } from "utils/transform";

const getRequiredFields = (selectedStepType) => {
  const requiredFieldsMap = {
    READ: ["text"],
    WRITE: ["text"],
    READ_SELECTOR: ["wait"],
    WAIT: ["wait"],
    BACKGROUND: ["text"],
    INPUT: ["text"],
    USERNAME: ["text"],
    PASSWORD: ["text"],
  };
  return requiredFieldsMap[selectedStepType] || ["index"];
};

const DisplayStepEditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const selectedStepType = useSelector(
    (store) => store.displayScenarios.selectedStepType
  );
  const indexOrText = useSelector(
    (store) => store.displayScenarios.indexOrText
  );
  const backgroundScenariosList = useSelector(
    (store) => store.displayScenarios.backgroundScenariosList
  );
  const options = {};
  options["text"] = getBackgroundScenariosList(backgroundScenariosList);

  const dispatch = useDispatch();

  //const inputNode = getInputNode(inputType);
  const [value, setValue] = useState(options?.text[0]?.value || "");

  let inputNode = getInputNode(inputType, dataIndex, value, setValue, options);

  const notValidationFields = [
    "maxLevenshteinDistance",
    "errorExecutionTime",
    "warningExecutionTime",
  ];

  let rules = [
    {
      required: true,
      message: `Please Input ${title}!`,
    },
  ];
  if (notValidationFields.includes(dataIndex)) {
    rules = [];
  }

  const requiredFields = getRequiredFields(selectedStepType);

  const indexChangeHandler = (value) =>
    dispatch(
      setIndexOrText(
        value === ""
          ? { index: true, text: true }
          : { index: true, text: false }
      )
    );
  const textChangeHandler = (value) =>
    dispatch(
      setIndexOrText(
        value === ""
          ? { index: true, text: true }
          : { index: false, text: true }
      )
    );

  const prepareCells = () => {
    if (selectedStepType === "CLICK") {
      if (dataIndex === "index") {
        return (
          <StlFormItem dataIndex={dataIndex} rules={rules} title={title}>
            <Input
              type="number"
              style={{ width: "170px" }}
              disabled={!indexOrText.index}
              onChange={(e) => indexChangeHandler(e.target.value)}
            />
          </StlFormItem>
        );
      } else if (dataIndex === "text") {
        return (
          <StlFormItem dataIndex={dataIndex} rules={rules} title={title}>
            <ReactTextareaAutosize
              autoFocus
              style={{ width: "100%" }}
              disabled={!indexOrText.text}
              onChange={(e) => textChangeHandler(e.target.value)}
            />
          </StlFormItem>
        );
      } else {
        return (
          <StlFormItem dataIndex={dataIndex} rules={rules} title={title}>
            {inputNode}
          </StlFormItem>
        );
      }
    } else {
      return requiredFields.includes(dataIndex) ? (
        <StlFormItem dataIndex={dataIndex} rules={rules} title={title}>
          {inputNode}
        </StlFormItem>
      ) : (
        <StlFormItem dataIndex={dataIndex} rules={rules} title={title}>
          {inputNode}
        </StlFormItem>
      );
    }
  };

  const editingCells = prepareCells();

  return (
    <td {...restProps} key={index + "h"}>
      {editing ? editingCells : children}
    </td>
  );
};

export default DisplayStepEditableCell;
