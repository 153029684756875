import React from "react";
import { Route, Routes } from "react-router-dom";
import { ConfigurationDetailPage } from "pages/configurationDetailPage/ConfigurationDetailPage";
import ConfigurationPage from "pages/configurationPage/ConfigurationPage";
import DisplayPage from "pages/displayPage/DisplayPage";
import { DisplayDetailPage } from "pages/displayDetailPage/DisplayDetailPage";
import { LoginPage } from "pages/loginPage/LoginPage";
import NotFoundPage from "pages/notFoundPage/NotFoundPage";
import { VoiceDetailPage } from "pages/voiceDetailPage/VoiceDetailPage";
import VoicePage from "pages/voicePage/VoicePage";
import MsisdnPage from "pages/msisdnPage/MsisdnPage";
import ProtectedComponent from "components/ProtectedComponent";

export const BaseRouter = () => {
  return (
    <Routes>
      <Route path={"/protectedcomp"} element={<ProtectedComponent />}></Route>
      <Route index element={<DisplayPage />} />
      <Route path="/display" element={<DisplayPage />} />
      <Route path="/voice" element={<VoicePage />} />
      <Route path="/configuration" element={<ConfigurationPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path={"/voice/:scenarioID"} element={<VoiceDetailPage />} />
      <Route path={"/display/:scenarioID"} element={<DisplayDetailPage />} />
      <Route path={"/msisdn"} element={<MsisdnPage />} />
      <Route
        path={"/config/:configurationID"}
        element={<ConfigurationDetailPage />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default BaseRouter;
