import React from "react";
import { Card } from "antd";
import StlFrame from "components/shared/StlFrame";
import LoginButton from "components/shared/loginButton/LoginButton";

export const LoginPage = () => {
  return (
    <StlFrame>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh",
        }}
      >
        <Card
          title="Sign In"
          style={{
            textAlign: "center",
            borderRadius: "20px",
            width: "300px",

            padding: "5px",
          }}
        >
          <LoginButton />
        </Card>
      </div>
    </StlFrame>
  );
};
