import { Select, Tooltip } from "antd";
import StlButton from "components/shared/StlButton";
import { getColumnStatus } from "utils/columnsUtils";

const actionsTransform = (actions) => {
  return actions.map((action) => {
    return { value: action.value, label: action.value };
  });
};

export const getPerformedTestDetailColumns = (
  actions,
  onChangeHandler,
  onClickHandler,
  isBtnLoading
) => {
  const items = actionsTransform(actions);

  return [
    { title: "Performed Test ID", dataIndex: "id", key: "id" },
    {
      title: "Audio",
      dataIndex: "audioUrl",
      key: "audioUrl",
      render: (audioUrl) => {
        if (audioUrl != null) {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <audio controls src={"https://" + audioUrl}>
                  Your browser does not support the <code>audio</code> element.
                </audio>
              </div>
            </>
          );
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "6%",
      render: getColumnStatus,
    },
    {
      title: "Check",
      dataIndex: "check",
      key: "check",
      width: "25%",
      render: (check) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Select
              placeholder="Select a Check"
              style={{
                width: 120,
              }}
              defaultValue={check}
              onChange={onChangeHandler}
              options={items}
            />
            <StlButton
              buttonType="primary"
              iconName="save"
              size="small"
              onClickHandler={onClickHandler}
              buttonStyle={{ marginLeft: "5px" }}
              loading={isBtnLoading}
            />
          </div>
        );
      },
    },
    {
      title: "checkedBy",
      dataIndex: "checkedBy",
      key: "checkedBy",
      width: "10%",
      ellipsis: {
        showTitle: false,
      },
      render: (checkedBy) => (
        <Tooltip placement="topLeft" title={checkedBy}>
          {checkedBy}
        </Tooltip>
      ),
    },
  ];
};
