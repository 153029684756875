import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "antd";
import {
  setIsEditing,
  setIsBeingAdded,
} from "features/slices/configuration/sentinelConfigurationSlice";
import {
  deleteConfigurationsById,
  getAllConfigurations,
} from "features/slices/configuration/sentinelConfigurationThunk";
import StlTable from "components/shared/StlTable";
import AlertMessage from "components/shared/AlertMessage";
import StlFormItem from "components/shared/StlFormItem";
import StlButton from "components/shared/StlButton";

import { getInputNode } from "utils";
import { getConfigurationColumns } from "./SentinelConfigurationColumns";
import ConfigurationService from "services/ConfigurationService";
import { useNavigate } from "react-router-dom";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = getInputNode(inputType);

  return (
    <td {...restProps}>
      {editing ? (
        <StlFormItem title={title} dataIndex={dataIndex}>
          {inputNode}
        </StlFormItem>
      ) : (
        children
      )}
    </td>
  );
};

const SentinelConfiguration = (props) => {
  const configurationsRaw = useSelector(
    (store) => store.sentinelConfiguration.configurations
  );

  const loading = useSelector((store) => store.sentinelConfiguration.loading);
  const failed = useSelector((store) => store.sentinelConfiguration.failed);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getAllConfigurations());
  }, [dispatch]);

  const addANewRow = async () => {
    const newSentinelConfig = {
      id: null,
      param: "",
      value: "",
    };

    const newRowFromServer = await ConfigurationService.create(
      newSentinelConfig
    );

    dispatch(setIsBeingAdded(true));
    dispatch(setIsEditing(true));

    const theID = newRowFromServer.id;

    await dispatch(getAllConfigurations());

    navigate("/config/" + theID);
  };

  const deleteSentinelConfiguration = async (id) => {
    await dispatch(deleteConfigurationsById(id));
    dispatch(getAllConfigurations());
  };

  const prepareConfigurations = (configurationsRaw) => {
    const copiedConfigs = JSON.parse(JSON.stringify(configurationsRaw));
    copiedConfigs.forEach(
      (configuration) => (configuration.key = configuration.id)
    );
    return copiedConfigs;
  };

  const configurations = prepareConfigurations(configurationsRaw);

  const columnsConfig = {
    deleteSentinelConfiguration,
  };
  const columns = getConfigurationColumns(columnsConfig);

  return (
    <Form form={form}>
      <div style={{ textAlign: "right" }}>
        {failed ? <AlertMessage /> : null}
        <StlButton
          ghost={false}
          buttonStyle={{
            marginBottom: "10px",
            color: "rgba(205, 84, 71, 0.8)",
          }}
          buttonType="dashed"
          onClickHandler={addANewRow}
          buttonShape="default"
        >
          Add Configuration
        </StlButton>
      </div>
      <StlTable
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        dataSource={configurations}
        columns={columns}
        loading={loading}
        pagination={{ showSizeChanger: true }}
      />
    </Form>
  );
};

export default SentinelConfiguration;
