import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

const StlSelectBox = ({ options, onChangeHandler, ...rest }) => {
  return (
    <>
      <Select
        defaultValue={[options[0]]}
        style={{ width: "110px" }}
        onChange={onChangeHandler}
        options={options}
        {...rest}
      />
    </>
  );
};

StlSelectBox.propTypes = {
  options: PropTypes.array.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};
StlSelectBox.defaultProps = {
  options: [],
};

export default StlSelectBox;
