import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Input, Form, Popconfirm, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addScheduling,
  deleteScheduling,
} from "features/slices/voice/voiceSchedulingsSlice";
import StlModal from "components/shared/StlModal";
import StlCron from "components/shared/StlCron";
import {
  editSchedulings,
  getAllSchedulings,
} from "features/slices/voice/voiceSchedulingsThunk";
import StlTable from "components/shared/StlTable";
import StlFormItem from "components/shared/StlFormItem";
import StlButton from "components/shared/StlButton";
import { useHover } from "hooks/useHover";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => (
  <td {...restProps} key={index + "h"}>
    {editing ? (
      <StlFormItem
        dataIndex={dataIndex}
        title={title}
        styles={{ display: "flex", alignItems: "center" }}
      >
        <Input />
      </StlFormItem>
    ) : (
      children
    )}
  </td>
);

const VOICEScheduling = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const loading = useSelector((store) => store.voiceSchedulings.loading);
  const schedulingsRaw = useSelector(
    (store) => store.voiceSchedulings.schedulings
  );
  const columnsRaw = useSelector((store) => store.voiceSchedulings.columns);

  const [editingKey, setEditingKey] = useState("");
  const [isBeingAdded, setIsBeingAdded] = useState(false);
  const [newCronRegex, setNewCronRegex] = useState("");
  const [visible, setVisible] = useState(false);

  const { buttonStyle, handleMouseEnter, handleMouseLeave } = useHover();

  useEffect(() => {
    dispatch(getAllSchedulings(params.scenarioID));
  }, [dispatch, params.scenarioID]);

  const onChangeHandle = (cronRegex) => {
    setNewCronRegex(cronRegex);
  };

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const prepareScheduling = (schedulingsRaw) => {
    const schedulings = JSON.parse(JSON.stringify(schedulingsRaw));
    schedulings.forEach((e, index) => {
      e.key = index;
      e.number = index + 1;
    });
    return schedulings;
  };

  const schedulings = prepareScheduling(schedulingsRaw);

  const prepareColumns = (columnsRaw) => {
    const columnsCopied = JSON.parse(JSON.stringify(columnsRaw));

    const actionsToAdd = {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      align: "center",
      render: (_, record) =>
        record.key === editingKey ? (
          <>
            <StlButton iconName="tool" onClickHandler={showModal} />
            {/*  <Button
              onClick={() => {
                showModal();
              }}
              icon={<ToolFilled style={{ color: "#1890FF" }} />}
              ghost
              shape="circle"
            /> */}
            {visible && (
              <StlModal
                title="Cron expression Tool"
                width={700}
                open={visible}
                onOkHandle={() => {
                  form.setFieldsValue({
                    scheduling: newCronRegex,
                  });
                  hideModal();
                }}
                onCancelHandle={hideModal}
              >
                <StlCron
                  value={record.scheduling}
                  onChangeHandle={onChangeHandle}
                />
              </StlModal>
            )}
            <StlButton
              iconName="save"
              buttonStyle={{
                marginRight: 8,
              }}
              onClickHandler={() => save(record.key)}
            />

            <Popconfirm title="Sure to cancel?" onConfirm={() => cancel()}>
              <StlButton
                iconName="cancel"
                buttonStyle={buttonStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </Popconfirm>
          </>
        ) : (
          <Space>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => deleteRow(record.key)}
            >
              <StlButton iconName="delete" />
            </Popconfirm>
            <StlButton iconName="edit" onClickHandler={() => edit(record)} />
          </Space>
        ),
    };

    columnsCopied.push(actionsToAdd);
    columnsCopied.forEach((e) => {
      if (e.dataIndex === "scheduling") {
        e.editable = true;
      }
    });

    const mergedColumns = columnsCopied.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "number" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: record.key === editingKey,
        }),
      };
    });

    return mergedColumns;
  };
  const columns = prepareColumns(columnsRaw);

  const addNewScheduling = () => {
    form.setFieldsValue({
      scheduling: "",
    });
    setIsBeingAdded(true);
    dispatch(addScheduling());
    const theKey = schedulingsRaw.length;
    setEditingKey(theKey);
  };

  const edit = (record) => {
    setEditingKey(record.key);
    form.setFieldsValue({
      ...record,
    });
  };

  const save = async (key) => {
    const row = await form.validateFields();
    let copiedSchedulings = JSON.parse(JSON.stringify(schedulingsRaw));
    copiedSchedulings.splice(key, 1, row);
    await dispatch(
      editSchedulings({
        schedulings: copiedSchedulings,
        scenarioID: params.scenarioID,
      })
    );
    await dispatch(getAllSchedulings(params.scenarioID));
    setEditingKey("");
    setIsBeingAdded(false);
  };

  const cancel = () => {
    setEditingKey("");
    if (isBeingAdded) {
      dispatch(deleteScheduling());
      setIsBeingAdded(false);
    }
  };

  const deleteRow = async (key) => {
    const copiedSchedulings = JSON.parse(JSON.stringify(schedulingsRaw));
    copiedSchedulings.splice(key, 1);
    await dispatch(
      editSchedulings({
        schedulings: copiedSchedulings,
        scenarioID: params.scenarioID,
      })
    );
    await dispatch(getAllSchedulings(params.scenarioID));
  };

  return (
    <Form form={form}>
      <div style={{ textAlign: "right" }}>
        <StlButton
          buttonType="dashed"
          buttonStyle={{
            marginBottom: "10px",
            color: "rgba(205, 84, 71, 0.8)",
          }}
          ghost={false}
          onClickHandler={addNewScheduling}
          buttonShape="default"
        >
          Add Scheduling
        </StlButton>
      </div>
      <StlTable
        components={{ body: { cell: EditableCell } }}
        dataSource={schedulings}
        columns={columns}
        loading={loading}
      />
    </Form>
  );
};

export default VOICEScheduling;
