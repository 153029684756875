import { createAsyncThunk } from "@reduxjs/toolkit";
import DisplayScheduleService from "services/DisplayScheduleService";

const name = "displaySchedules";

export const getAllDisplaySchedules = createAsyncThunk(
  `${name}/getAllDisplaySchedules`,
  () => {
    return DisplayScheduleService.getAll();
  }
);

export const getAllSchedulesByScenarioId = createAsyncThunk(
  `${name}/getAllScheduleByScenarioId`,
  (scenarioID) => {
    return DisplayScheduleService.getSchedulesByScenarioId(scenarioID);
  }
);

export const editSchedulesByScenarioId = createAsyncThunk(
  `${name}/editSchedulesByScenarioId`,
  (data) => {
    return DisplayScheduleService.update(data);
  }
);
