import { Popconfirm, Space } from "antd";

import StlButton from "components/shared/StlButton";
import { deepCopy, sortColumnFunc } from "utils";
import { getColumnSchedule, getColumnState } from "utils/columnsUtils";

const prepareColumns = (columnsRaw, isEditing) => {
  const cols = deepCopy(columnsRaw);

  const mergedColumns = cols.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing,
      }),
    };
  });
  return mergedColumns;
};

export const displayScenariosColumns = (columnsConfig) => {
  const defaultConfig = {
    schedules: null,
    deleteScenario: null,
    magnifierClickHandler: null,
    isEditing: null,
  };

  const params = { ...defaultConfig, ...columnsConfig };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      dataType: "text",
      sorter: sortColumnFunc("name"),
    },
    {
      title: "Type",
      dataIndex: "scenarioType",
      key: "type",
      editable: true,
      dataType: "text",
      sorter: sortColumnFunc("scenarioType"),
      filters: [
        {
          text: "standard",
          value: "STANDARD",
        },
        {
          text: "background",
          value: "BACKGROUND",
        },
      ],
      onFilter: (value, record) => {
        const scenarioType = record?.scenarioType ?? "";
        return scenarioType.indexOf(value) === 0;
      },
    },
    {
      title: "Touchpoint",
      dataIndex: "touchpoint",
      key: "touchpoint",
      editable: true,
      dataType: "text",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      editable: true,
      dataType: "text",
      sorter: sortColumnFunc("description"),
    },

    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      editable: true,
      inputType: "selectBox",
      dataType: "text",
      sorter: sortColumnFunc("userType"),
      filters: [
        {
          text: "consumer",
          value: "consumer",
        },
        {
          text: "business",
          value: "business",
        },
        {
          text: "next_consumer",
          value: "next_consumer",
        },
        {
          text: "simple_consumer",
          value: "simple_consumer",
        },
        {
          text: "simple_business",
          value: "simple_business",
        },
      ],
      onFilter: (value, record) => {
        const userType = record?.userType ?? "";
        return userType.indexOf(value) === 0;
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      editable: true,
      inputType: "selectBox",
      dataType: "text",
      render: getColumnState,
    },
    /* {
      title: () => <Tooltip title="Max Levenshtein Distance">MLD</Tooltip>,
      dataIndex: "maxLevenshteinDistance",
      key: "maxLevenshteinDistance",
      editable: true,
      inputType: "number",
      dataType: "number",
    },
    {
      title: () => <Tooltip title="Error Execution Time">EET</Tooltip>,
      dataIndex: "errorExecutionTime",
      key: "errorExecutionTime",
      editable: true,
      inputType: "number",
      dataType: "number",
    },
    {
      title: () => <Tooltip title="Warning Execution Time">WET</Tooltip>,
      dataIndex: "warningExecutionTime",
      key: "warningExecutionTime",
      editable: true,
      inputType: "number",
      dataType: "number",
    },   {
      title: "URL",
      dataIndex: "url",
      key: "url",
      editable: true,
      dataType: "text",
    }, */
  ];

  if (params.schedules) {
    columns.push({
      title: "Schedules",
      dataIndex: "schedules",
      key: "schedules",
      filters: [
        {
          text: "Scheduled",
          value: "YES",
        },
        {
          text: "Not Scheduled",
          value: "NOT",
        },
      ],
      onFilter: (value, record) => {
        const row = params.schedules[record.id] ?? null;
        let scheduleValue = row && row.length > 0 ? "YES" : "NOT";
        console.log("scheduleValue", scheduleValue);
        return scheduleValue.indexOf(value) === 0;
      },
      render: (_, record) => {
        const row = params.schedules[record.id] ?? null;
        let scheduleValue = row && row.length > 0 ? "YES" : "NOT";
        return getColumnSchedule(scheduleValue);
      },
    });
  }

  if (params.deleteScenario) {
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => {
        const magnifier = (
          <StlButton
            iconName="zoomIn"
            onClickHandler={() => params.magnifierClickHandler(record)}
          />
        );
        return (
          <Space>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => params.deleteScenario(record.id)}
            >
              <StlButton iconName="delete" />
            </Popconfirm>
            {magnifier}
          </Space>
        );
      },
    });
  }
  return prepareColumns(columns, params.isEditing);
};
