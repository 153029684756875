import React from "react";
import "./App.scss";
import ProtectedComponent from "components/ProtectedComponent";

import {
  BrowserRouter as Router,
  BrowserRouter,
  Route,
} from "react-router-dom";
import { useSelector } from "react-redux";

import { REPORT_API_ENDPOINT } from "utils";
import LoginButton from "components/shared/loginButton/LoginButton";
// import {REACT_APP_CLOUDFRONT_DISTRIBUTION} from "utils";

function LogCheck() {
  const actualIdToken = useSelector(
    (store) => store.personalInfo.actualIdToken
  );
  console.log("Dentro CheckLog");
  console.log("AMBIENTE DI BUILD:");
  console.log(REPORT_API_ENDPOINT);
  // console.log("Link Cloudfront:")
  // console.log(REACT_APP_CLOUDFRONT_DISTRIBUTION);

  if (actualIdToken == null) {
    console.log("Token not acquired");
    return <LoginButton />;
  }
  console.log("Token acquired");
  return <div></div>;
}

const App = () => {
  return (
    <>
      <LogCheck />
      {/*<LoginButton />*/}

      {/*</BrowserRouter>*/}
      {/*<RecoilRoot>*/}
      {/*    <LoginButton />*/}

      <ProtectedComponent />

      {/*</RecoilRoot>*/}

      {/*<Router>*/}
      {/*    <Switch>*/}
      {/*        <Route path={"/protectedcomp"}>*/}
      {/*            <ProtectedComponent/>*/}
      {/*        </Route>*/}
      {/*        <Route path="/login">*/}
      {/*            <LoginButton />*/}
      {/*        </Route>*/}
      {/*    </Switch>*/}
      {/*</Router>*/}

      {/*<AuthenticatedTemplate>*/}
      {/*  <ProtectedComponent />*/}
      {/*</AuthenticatedTemplate>*/}
    </>
  );
};

export default App;
