import { Form } from "antd";
import EditableCell from "components/shared/editableCell";
import StlButton from "components/shared/StlButton";
import StlTable from "components/shared/StlTable";
import useDisplayScheduling from "hooks/useDisplayScheduling";

const DisplayScheduling = () => {
  const { form, addNewScheduling, schedulings, columns, loading } =
    useDisplayScheduling();
  return (
    <Form form={form}>
      <div style={{ textAlign: "right" }}>
        <StlButton
          ghost={false}
          buttonType="dashed"
          buttonStyle={{
            marginBottom: "10px",
            color: "rgba(205, 84, 71, 0.8)",
          }}
          onClickHandler={addNewScheduling}
          buttonShape="default"
        >
          Add Scheduling
        </StlButton>
      </div>
      <StlTable
        components={{ body: { cell: EditableCell } }}
        dataSource={schedulings}
        columns={columns}
        loading={loading}
      />
    </Form>
  );
};

export default DisplayScheduling;
