import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";

import { callMsGraph } from "auth/graph";
import { loginRequest } from "auth/authConfig";
import { InteractionStatus } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import {
  setActualIdToken,
  setNameOfUser,
  setUserEmail,
} from "features/slices/user/personalInfoSlice";
import fetchIntercept from "fetch-intercept";
import { Auth } from "@aws-amplify/auth";
import { fetchWrapper as axios } from "../utils/fetchWrapper";
import BaseRouter from "routes/BaseRouter";

const ProtectedComponent = () => {
  const [user, setUser] = useState();
  const actualIdToken = useSelector(
    (store) => store.personalInfo.actualIdToken
  );
  const dispatch = useDispatch();
  const [apiResponse, setApiResponse] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [canRender, setCanRender] = useState(false);

  async function getCurrentUser() {
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      setUser(authUser);
      dispatch(
        setActualIdToken(authUser["signInUserSession"]["idToken"]["jwtToken"])
      );
      dispatch(setUserEmail(authUser.username));
    } catch (e) {
      console.log("error happened", e);
      setUser(null);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCurrentUser();
  }, []);

  async function handleCallProtectedMethod(event) {
    event.preventDefault();
    const accessToken = user["signInUserSession"]["accessToken"]["jwtToken"];
    const headers = {
      Authorization: accessToken,
    };

    const apiResp = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/awesomeapi`,
      { headers }
    );
    setApiResponse(JSON.stringify(apiResp.data));
  }

  async function handleSignOut(event) {
    event.preventDefault();
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
    setUser(null);
    setIsLoading(false);
  }

  useEffect(() => {
    if (actualIdToken) {
      fetchIntercept.register({
        request: function (url, config) {
          const myConfig = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${actualIdToken}`,
            },
          };
          const fullConfig = {
            ...config,
            ...myConfig,
          };

          return [url, fullConfig];
        },
      });
      setCanRender(true);
    }
  }, [actualIdToken]);

  useEffect(() => {
    console.log("Dentro Graph");
  }, [user, dispatch]);

  return canRender ? <BaseRouter /> : <p>Loading...</p>;
};

export default ProtectedComponent;
