import { Popconfirm, Space, Tooltip } from "antd";
import StlButton from "components/shared/StlButton";
import { deepCopy } from "utils";
import { getColumnState } from "utils/columnsUtils";

const prepareColumns = (columnsRaw, isEditing) => {
  const cols = deepCopy(columnsRaw);

  const mergedColumns = cols.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing,
      }),
    };
  });
  return mergedColumns;
};

export const getVoiceScenariosDescriptionColumns = (columnsConfig) => {
  const defaultConfig = {
    schedules: null,
    deleteScenario: null,
    showDetail: null,
    isEditing: null,
    save: null,
    edit: null,
    execute: null,
    buttonStyle: null,
    handleMouseEnter: null,
    handleMouseLeave: null,
  };
  const params = { ...defaultConfig, ...columnsConfig };
  const columns = [
    { title: "ID", dataIndex: "id", key: "id", dataType: "text" },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      dataType: "text",
      inputType: "text",
    },
    {
      title: "Type",
      dataIndex: "scenarioType",
      key: "type",
      editable: true,
      inputType: "selectBox",
      dataType: "text",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      editable: true,
      inputType: "text",
      dataType: "text",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      editable: true,
      inputType: "selectBox",
      dataType: "text",
      render: getColumnState,
    },
    /*  {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      dataType: "text",
      inputType: "text",
      render: (stringDate) => readableDate(stringDate),
    }, */
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      editable: true,
      dataType: "text",
      inputType: "selectBox",
    },
    {
      title: () => <Tooltip title="Max Levenshtein Distance">MLD</Tooltip>,
      dataIndex: "maxLevenshteinDistance",
      key: "maxLevenshteinDistance",
      editable: true,
      dataType: "number",
      inputType: "number",
    },
    {
      title: () => <Tooltip title="Error Execution Time">EET</Tooltip>,
      dataIndex: "errorTime",
      key: "errorExecutionTime",
      editable: true,
      dataType: "number",
      inputType: "number",
    },
    {
      title: () => <Tooltip title="Warning Execution Time">WET</Tooltip>,
      dataIndex: "warningTime",
      key: "warningExecutionTime",
      editable: true,
      dataType: "number",
      inputType: "number",
    },
    {
      title: "From",
      dataIndex: "fromNumber",
      key: "fromNumber",
      editable: true,
      dataType: "text",
      inputType: "text",
    },
    {
      title: "To",
      dataIndex: "toNumber",
      key: "toNumber",
      editable: true,
      dataType: "toNumber",
      inputType: "toNumber",
    },
  ];

  columns.push({
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (_, record) => {
      return params.isEditing ? (
        <>
          <StlButton
            iconName="save"
            onClickHandler={params.save}
            buttonStyle={{
              marginRight: 8,
            }}
          ></StlButton>

          {params.isBeingAdded ? null : (
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => params.cancel()}
            >
              <StlButton
                iconName="cancel"
                buttonStyle={params.buttonStyle}
                onMouseEnter={params.handleMouseEnter}
                onMouseLeave={params.handleMouseLeave}
              />
            </Popconfirm>
          )}
        </>
      ) : (
        <Space>
          <StlButton
            iconName="edit"
            onClickHandler={() => params.edit(record)}
          />
          <Popconfirm
            title="Sure to execute?"
            onConfirm={() => params.execute(record)}
          >
            <StlButton iconName="play" />
          </Popconfirm>
        </Space>
      );
    },
  });
  return prepareColumns(columns, params.isEditing);
};
