import { Tooltip } from "antd";

import { deepCopy } from "utils";
import { getColumnState } from "utils/columnsUtils";

const prepareColumns = (columnsRaw, isEditing) => {
  const cols = deepCopy(columnsRaw);

  const mergedColumns = cols.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing,
      }),
    };
  });
  return mergedColumns;
};

export const displayScenarioDescriptionColumns = (columnsConfig) => {
  const defaultConfig = {
    schedules: null,
    deleteScenario: null,
    magnifierClickHandler: null,
    isEditing: null,
  };

  const params = { ...defaultConfig, ...columnsConfig };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      dataType: "text",
    },
    {
      title: "Type",
      dataIndex: "scenarioType",
      key: "type",
      editable: true,
      inputType: "selectBox",
      dataType: "text",
    },
    {
      title: "Touchpoint",
      dataIndex: "touchpoint",
      key: "touchpoint",
      editable: true,
      dataType: "text",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      editable: true,
      dataType: "text",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      editable: true,
      inputType: "selectBox",
      dataType: "text",
      render: getColumnState,
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      editable: true,
      inputType: "selectBox",
      dataType: "text",
    },
    {
      title: () => <Tooltip title="Max Levenshtein Distance">MLD</Tooltip>,
      dataIndex: "maxLevenshteinDistance",
      key: "maxLevenshteinDistance",
      editable: true,
      inputType: "number",
      dataType: "number",
    },
    {
      title: () => <Tooltip title="Error Execution Time">EET</Tooltip>,
      dataIndex: "errorExecutionTime",
      key: "errorExecutionTime",
      editable: true,
      inputType: "number",
      dataType: "number",
    },
    {
      title: () => <Tooltip title="Warning Execution Time">WET</Tooltip>,
      dataIndex: "warningExecutionTime",
      key: "warningExecutionTime",
      editable: true,
      inputType: "number",
      dataType: "number",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      editable: true,
      dataType: "text",
    },
  ];

  return prepareColumns(columns, params.isEditing);
};
