import PropTypes from "prop-types";
import {
  AudioFilled,
  CaretLeftFilled,
  CloseCircleOutlined,
  DeleteTwoTone,
  DownloadOutlined,
  EditTwoTone,
  FileImageOutlined,
  LogoutOutlined,
  PauseCircleFilled,
  PlayCircleOutlined,
  PlusSquareTwoTone,
  SaveFilled,
  SendOutlined,
  SyncOutlined,
  ToolFilled,
  ZoomInOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const buttonColors = {
  danger: { color: "#ff0000" },
  normal: { color: "#1890FF" },
};
const icons = {
  delete: <DeleteTwoTone />,
  cancel: <CloseCircleOutlined style={buttonColors.danger} />,
  edit: <EditTwoTone style={buttonColors.normal} />,
  add: <PlusSquareTwoTone style={buttonColors.normal} />,
  save: <SaveFilled style={buttonColors.normal} />,
  tool: <ToolFilled style={buttonColors.normal} />,
  goBack: <CaretLeftFilled />,
  zoomIn: <ZoomInOutlined style={buttonColors.normal} />,
  image: <FileImageOutlined style={buttonColors.normal} />,
  pause: <PauseCircleFilled style={buttonColors.danger} />,
  microphone: <AudioFilled style={buttonColors.danger} />,
  send: <SendOutlined style={buttonColors.normal} />,
  logout: <LogoutOutlined />,
  download: <DownloadOutlined style={buttonColors.normal} />,
  play: <PlayCircleOutlined style={buttonColors.normal} />,
  update: <SyncOutlined style={buttonColors.normal} />,
};

const StlButton = ({
  buttonType,
  children,
  iconName,
  onClickHandler,
  buttonStyle,
  disabled,
  buttonShape,
  ghost,
  ...rest
}) => {
  const iconButton = icons[iconName];

  return (
    <Button
      className="StlButton"
      type={buttonType}
      icon={iconButton}
      shape={buttonShape}
      onClick={onClickHandler}
      style={buttonStyle}
      disabled={disabled}
      ghost={ghost}
      {...rest}
    >
      {children}
    </Button>
  );
};

StlButton.propTypes = {
  buttonType: PropTypes.oneOf(["primary", "text", "ghost", "link", "dashed"]),
  children: PropTypes.node,
  iconName: PropTypes.oneOf([
    "delete",
    "cancel",
    "edit",
    "add",
    "save",
    "tool",
    "goBack",
    "zoomIn",
    "image",
    "pause",
    "microphone",
    "send",
    "logout",
    "download",
    "play",
    "update",
  ]),
  onClickHandler: PropTypes.func,
  buttonStyle: PropTypes.object,
  disabled: PropTypes.bool,
  buttonShape: PropTypes.oneOf(["default", "circle", "round"]),
  ghost: PropTypes.bool,
};

StlButton.defaultProps = {
  buttonType: "ghost",
  ghost: true,
  buttonShape: "circle",
};

export default StlButton;
