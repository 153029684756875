import { createAsyncThunk } from "@reduxjs/toolkit";
import { REPORT_API_ENDPOINT } from "utils";
import { fetchWrapper } from "utils/fetchWrapper";

const name = "voicePerformedTestSlice";

export const getPerformedTestsByIdScenario = createAsyncThunk(
  `${name}/getPerformedTestsByIdScenario`,
  (idScenario) => {
    return fetchWrapper
      .post(REPORT_API_ENDPOINT + "voice/search", {
        criteria: [
          {
            field: "idScenario",
            operator: "eq",
            value: idScenario,
          },
        ],
      })
      .catch((error) => {
        console.log(error);
      });
  }
);
