import React, { useEffect, useState } from "react";
import { Form, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import {
  addStep,
  deleteStep,
  setSelectedStepType,
} from "features/slices/display/displayScenariosSlice";
import {
  editDisplayScenario,
  getAllBackgroundScenarios,
  getDisplayScenarioById,
} from "features/slices/display/displayScenariosThunk";
import StlTable from "components/shared/StlTable";
import AlertMessage from "components/shared/AlertMessage";

import StlButton from "components/shared/StlButton";
import { useHover } from "hooks/useHover";
import { getDisplayStepsColumns } from "./DisplayStepsColumns";

import DisplayStepEditableCell from "./DisplayStepEditableCell";
import { deepCopy } from "utils";

const DisplaySteps = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [form] = Form.useForm();

  const [isBeingAdded, setIsBeingAdded] = useState(false);
  const [areMultipleSelected, setAreMultipleSelected] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [selectedSteps, setSelectedSteps] = useState([]);

  const { buttonStyle, handleMouseEnter, handleMouseLeave } = useHover();

  const onSelectTypeHandler = (type) => {
    dispatch(setSelectedStepType(type));
  };

  useEffect(() => {
    dispatch(getDisplayScenarioById(params.scenarioID));
    dispatch(getAllBackgroundScenarios());
  }, [dispatch, params.scenarioID]);

  const theScenario = useSelector(
    (store) =>
      store.displayScenarios.scenarios.filter(
        (e) => e.id === params.scenarioID
      )[0]
  );

  const stepsRaw = theScenario
    ? theScenario.steps
      ? theScenario.steps
      : []
    : [];

  const loading = useSelector((store) => store.displayScenarios.loading);

  const failed = useSelector((store) => store.displayScenarios.failed);

  const scenarioIsBeingAdded = useSelector(
    (store) => store.displayScenarios.isBeingAdded
  );

  const selectedStepType = useSelector(
    (store) => store.displayScenarios.selectedStepType
  );

  const addANewRow = (stepID) => {
    setIsBeingAdded(true);
    dispatch(addStep({ scenarioID: theScenario.id, stepID: stepID }));
    form.setFieldsValue({
      number: "",
      text: "",
      inputField: "",
      stepId: "",
    });
    setEditingKey("New Step");
  };

  const save = async (id) => {
    const row = await form.validateFields();
    let stepsCopied = deepCopy(stepsRaw);
    let theScenarioCopied = deepCopy(theScenario);
    let updatingStep = stepsCopied.filter((e) => e.id === id)[0];
    let updatedStep = {
      ...updatingStep,
      ...row,
      ...{ type: selectedStepType },
    };
    if (updatedStep.id === "New Step") {
      updatedStep.id = null;
    }
    stepsCopied.splice(
      stepsCopied.findIndex((e) => e.id === id),
      1,
      updatedStep
    );
    theScenarioCopied.steps = stepsCopied;
    await dispatch(editDisplayScenario(theScenarioCopied));
    await dispatch(getDisplayScenarioById(params.scenarioID));

    setIsBeingAdded(false);
    setEditingKey("");
  };

  const cancel = (id) => {
    if (isBeingAdded) {
      dispatch(deleteStep({ scenarioID: theScenario.id, stepID: id }));
    }

    setEditingKey("");
    setIsBeingAdded(false);
  };

  const edit = (record) => {
    form.setFieldsValue({
      number: "",
      text: "",
      inputField: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const deleteRow = async (id) => {
    const scenario = JSON.parse(JSON.stringify(theScenario));
    const newSteps = scenario.steps.filter((e) => !id.includes(e.id));
    scenario.steps = newSteps;
    await dispatch(editDisplayScenario(scenario));
    await dispatch(getDisplayScenarioById(params.scenarioID));
    setAreMultipleSelected(false);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      selectedRows.length > 0
        ? setAreMultipleSelected(true)
        : setAreMultipleSelected(false);
      setSelectedSteps(() => {
        let selectedIDs = [];
        selectedRows.forEach((e) => selectedIDs.push(e.id));
        return selectedIDs;
      });
    },
    getCheckboxProps: (record) => ({
      disabled: ["START", "END"].includes(record.type),
    }),
  };

  const prepareSteps = (stepsRaw) => {
    const copiedSteps = deepCopy(stepsRaw);
    copiedSteps.forEach((e) => (e.key = e.id));
    return copiedSteps;
  };

  const steps = prepareSteps(stepsRaw);

  const columns = getDisplayStepsColumns(
    editingKey,
    areMultipleSelected,
    isBeingAdded,
    scenarioIsBeingAdded,
    onSelectTypeHandler,
    save,
    cancel,
    buttonStyle,
    handleMouseEnter,
    handleMouseLeave,
    edit,
    deleteRow,
    addANewRow,
    selectedStepType
  );

  return (
    <Form form={form}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {failed ? <AlertMessage /> : null}
        {areMultipleSelected ? (
          <Popconfirm
            title="Sure to delete？"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteRow(selectedSteps)}
          >
            <StlButton
              buttonType="primary"
              buttonStyle={{ marginRight: "10px", marginBottom: 10 }}
              buttonShape="default"
              ghost={false}
              danger
            >
              {selectedSteps.length > 1
                ? "Delete Selected Steps"
                : "Delete Selected Step"}
            </StlButton>
          </Popconfirm>
        ) : null}
      </div>
      {theScenario ? (
        <StlTable
          components={{
            body: {
              cell: DisplayStepEditableCell,
            },
          }}
          rowSelection={rowSelection}
          loading={loading}
          dataSource={steps}
          columns={columns}
        />
      ) : (
        <h3>Scenario not found</h3>
      )}
    </Form>
  );
};

export default DisplaySteps;
