import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "antd";

const getFirstLetterFromFullName = (fullName) => {
  try {
    if (fullName) {
      /* const fullNameSplited = fullName.split(",");
      const lastName = fullNameSplited[0];
      const name = fullNameSplited[1]; */
      return fullName.substring(0, 2).toUpperCase();
    }
  } catch (error) {
    console.log(error);
  }
};
const StlUserInfo = ({ user }) => {
  return (
    <>
      <Avatar
        style={{ backgroundColor: "#900c3f", verticalAlign: "middle" }}
        size="large"
        gap={"4"}
      >
        {getFirstLetterFromFullName(user)}
      </Avatar>
    </>
  );
};

StlUserInfo.propTypes = {
  user: PropTypes.string,
};

export default StlUserInfo;
