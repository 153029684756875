import { EditTwoTone } from "@ant-design/icons";

import { Button, Popconfirm, Space } from "antd";
import StlButton from "components/shared/StlButton";
import { deepCopy } from "utils";

export const getConfigurationColumns = (options) => {
  const {
    deleteSentinelConfiguration,
    buttonStyle,
    handleMouseEnter,
    handleMouseLeave,
    isEditing,
    isBeingAdded,
    edit,
    cancel,
    save,
  } = options;

  const prepareColumns = (columns) => {
    const cols = deepCopy(columns);

    const mergedColumns = cols.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.inputType,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing,
        }),
      };
    });
    return mergedColumns;
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      responsive: ["md", "lg"],
      inputType: "text",
      editable: false,
    },
    {
      title: "Param",
      dataIndex: "param",
      key: "param",
      editable: true,
      inputType: "text",
    },
    {
      title: "Value",
      dataIndex: "value",
      editable: true,
      key: "value",
      inputType: "text",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      inputType: "text",
      responsive: ["lg"],
      editable: false,
    },
  ];

  if (deleteSentinelConfiguration) {
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => {
        return (
          <Space>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => deleteSentinelConfiguration(record.id)}
            >
              <StlButton iconName="delete" buttonShape="circle" ghost />
            </Popconfirm>
          </Space>
        );
      },
    });
  }

  if (edit) {
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => {
        return isEditing ? (
          <>
            <StlButton
              iconName="save"
              buttonStyle={{ marginRight: 8 }}
              onClickHandler={save}
            />

            {isBeingAdded ? null : (
              <Popconfirm title="Sure to cancel?" onConfirm={() => cancel}>
                <StlButton
                  iconName="cancel"
                  buttonStyle={buttonStyle}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
              </Popconfirm>
            )}
          </>
        ) : (
          <Space>
            <Button
              onClick={() => edit(record)}
              icon={<EditTwoTone />}
              shape="circle"
              ghost
            />
          </Space>
        );
      },
    });
  }

  return prepareColumns(columns);
};
