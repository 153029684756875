import React from "react";
import { Amplify } from "aws-amplify";
import { Card } from "antd";
import StlButton from "components/shared/StlButton";

const LoginButton = () => {
  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_AUTH_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: "USER_PASSWORD_AUTH",

      // OPTIONAL - Hosted UI configuration
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: ["awesomeapi-resource-server/awesomeapi.read", "openid"],
        redirectSignIn: process.env.REACT_APP_CLOUDFRONT_DISTRIBUTION,
        redirectSignOut: process.env.REACT_APP_CLOUDFRONT_DISTRIBUTION,
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Card
          title="TOBi Sentinel Configurator"
          headStyle={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#E50000",
            color: "#fff",
          }}
        >
          <Card.Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "300px",
              height: "150px",
              padding: "5PX",
            }}
          >
            {/*<a href="https://qa-sentinelcloud-dev-infra-domain.auth.eu-south-1.amazoncognito.com/login?client_id=564qavfrrbciok5buvs83g0gjl&response_type=code&scope=email+openid&redirect_uri=http://localhost:3000/protectedcomp">*/}
            {/*    <button>LogIn With Cognito</button>*/}
            {/*</a>*/}
            <StlButton
              // onClickHandler={() => loginHandler(instance)}
              buttonType="primary"
              buttonShape="default"
              href={`https://${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?client_id=${process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=${process.env.REACT_APP_CLOUDFRONT_DISTRIBUTION}`}
              // https://qa-sentinelcloud-dev-infra-domain.auth.eu-south-1.amazoncognito.com/login?client_id=564qavfrrbciok5buvs83g0gjl&response_type=code&scope=email+openid&redirect_uri=https://d3j7ces1cfdp5o.cloudfront.net/protectedcomp
              ghost={false}
            >
              Login with Cognito
            </StlButton>
          </Card.Grid>
        </Card>
      </div>
    </>
  );
};

export default LoginButton;

//   const { instance } = useMsal();
//
//   const loginHandler = (instance) => {
//     instance.loginRedirect(loginRequest).catch((e) => {
//       console.error(e);
//     });
//   };
//
//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         height: "100vh",
//       }}
//     >
//       <Card
//         title="TOBi Sentinel Configurator"
//         headStyle={{
//           display: "flex",
//           justifyContent: "center",
//           backgroundColor: "#E50000",
//           color: "#fff",
//         }}
//       >
//         <Card.Grid
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             width: "300px",
//             height: "150px",
//             padding: "5PX",
//           }}
//         >
//           <StlButton
//             onClickHandler={() => loginHandler(instance)}
//             buttonType="primary"
//             buttonShape="default"
//             ghost={false}
//           >
//             Login with AWS
//           </StlButton>
//         </Card.Grid>
//       </Card>
//     </div>
//   );
// };
//
// export default LoginButton;
