import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";

import {
  setIsEditing,
  setIsBeingAdded,
} from "features/slices/voice/voiceScenariosSlice";

import {
  deleteVoiceScenarioById,
  getAllVoiceScenarios,
  VOICE_URL,
} from "features/slices/voice/voiceScenariosThunk";

import { getAllVoiceSchedulings } from "features/slices/voice/voiceSchedulingsThunk";

import StlTable from "components/shared/StlTable";
import { getVoiceScenariosColumns } from "./VoiceScenariosColumns";
import AlertMessage from "components/shared/AlertMessage";

import StlButton from "components/shared/StlButton";
import VoiceScenariosEditableCell from "./VoiceScenariosEditableCell";

const VoiceScenarios = (props) => {
  const scenariosRaw = useSelector((store) => store.voiceScenarios.scenarios);
  const schedules = useSelector(
    (store) => store.voiceSchedulings.allSchedulings
  );
  const loading = useSelector((store) => store.voiceScenarios.loading);
  const failed = useSelector((store) => store.voiceScenarios.failed);
  const isBeingAdded = useSelector(
    (store) => store.voiceScenarios.isBeingAdded
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getAllVoiceScenarios());
    dispatch(getAllVoiceSchedulings());
  }, [dispatch]);

  const addANewRow = async () => {
    const newScenario = {
      id: null,
      url: "",
      maxLevenshteinDistance: 0,
      errorExecutionTime: 0,
      warningExecutionTime: 0,
      name: "",
      description: "",
      steps: [
        {
          id: null,
          stepId: "START",
          type: "START",
        },
        {
          id: null,
          stepId: "END",
          type: "END",
        },
      ],
    };
    const postAndGetNewRowFromServer = async (newScenario) => {
      const res = await fetch(VOICE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newScenario),
      });
      const promise = await res;
      const data = await promise.json();
      return data;
    };
    const newRowFromServer = await postAndGetNewRowFromServer(newScenario);

    dispatch(setIsBeingAdded(true));
    dispatch(setIsEditing(true));

    const theID = newRowFromServer.id;

    await dispatch(getAllVoiceScenarios());

    navigate("/voice/" + theID);
  };

  const deleteScenario = async (id) => {
    await dispatch(deleteVoiceScenarioById(id));
    dispatch(getAllVoiceScenarios());
  };

  const showDetail = (record) => {
    dispatch(setIsEditing(false));
    dispatch(setIsBeingAdded(false));
    navigate("/voice/" + record.id);
  };

  const prepareScenarios = (scenariosRaw) => {
    const copiedScenarios = JSON.parse(JSON.stringify(scenariosRaw));
    copiedScenarios.forEach((scenario) => (scenario.key = scenario.id));

    return copiedScenarios;
  };

  const scenarios = prepareScenarios(scenariosRaw);

  const voiceColumnsConfig = {
    schedules,
    deleteScenario,
    showDetail,
  };
  const columns = getVoiceScenariosColumns(voiceColumnsConfig);

  return (
    <Form form={form}>
      <div style={{ textAlign: "right" }}>
        {failed ? <AlertMessage /> : null}

        <StlButton
          buttonType="dashed"
          onClickHandler={addANewRow}
          buttonStyle={{
            marginBottom: "10px",
            color: "rgba(205, 84, 71, 0.8)",
          }}
          disabled={isBeingAdded}
          ghost={false}
          buttonShape="default"
        >
          Add Scenario
        </StlButton>
      </div>
      <StlTable
        components={{
          body: {
            cell: VoiceScenariosEditableCell,
          },
        }}
        dataSource={scenarios}
        columns={columns}
        loading={loading}
        pagination={{ showSizeChanger: true }}
      />
    </Form>
  );
};

export default VoiceScenarios;
