import { SCHEDULER_API_ENDPOINT } from "utils";
import { fetchWrapper } from "utils/fetchWrapper";
const DISPLAY_SCHEDULING_BASEURL = SCHEDULER_API_ENDPOINT + "display/";

const getAll = () => {
  return fetchWrapper.get(DISPLAY_SCHEDULING_BASEURL + "schedulings");
};
const getSchedulesByScenarioId = (id) => {
  return fetchWrapper.get(DISPLAY_SCHEDULING_BASEURL + id + "/scheduling");
};

const update = (data) => {
  return fetchWrapper.put(
    DISPLAY_SCHEDULING_BASEURL + data.scenarioID + "/scheduling",
    data.schedulings
  );
};

const DisplayScheduleService = {
  getAll,
  getSchedulesByScenarioId,
  update,
};

export default DisplayScheduleService;
