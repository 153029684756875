import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { getPerformedTestColumns } from "./VoicePerformedTestsColumns";

import { deepCopy, orderDescend } from "utils";
import AlertMessage from "components/shared/AlertMessage";
import StlTable from "components/shared/StlTable";
import StlModal from "components/shared/StlModal";
import PerformedTestDetail from "../performedTestDetail";
import { useDispatch, useSelector } from "react-redux";
import { getPerformedTestsByIdScenario } from "features/slices/voice/voicePerformedTest/voicePerformedTestThunk";
import { setPerformedTestItemSelected } from "features/slices/voice/voicePerformedTest/voicePerformedTestSlice";

const VoicePerformedTests = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [isVisible, setIsVisible] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);

  const failed = useSelector((store) => store.voicePerformedTests.failed);
  const loading = useSelector((store) => store.voicePerformedTests.loading);
  const performedTestRaw = useSelector(
    (store) => store.voicePerformedTests.performedTests
  );
  const performedTestItemSelected = useSelector(
    (store) => store.voicePerformedTests.performedTestItemSelected
  );

  const preparePerformedTests = (data) => {
    let copiedPerformedTestsRaw = deepCopy(data);
    copiedPerformedTestsRaw.forEach((e) => (e.key = e.id));
    return copiedPerformedTestsRaw;
  };

  const onChangeHandler = (pagination, filters, sorter) => {
    setSelectedFilters(filters);
    setSortedInfo(sorter);
  };

  const hideModal = () => {
    setIsVisible(false);
  };

  const columns = useMemo(() => {
    const showModal = (record) => {
      dispatch(setPerformedTestItemSelected(record));
      setIsVisible(true);
    };
    return getPerformedTestColumns(showModal, selectedFilters, sortedInfo);
  }, [dispatch, selectedFilters, sortedInfo]);

  useEffect(() => {
    dispatch(getPerformedTestsByIdScenario(params.scenarioID));
    return () => {};
  }, [dispatch, params.scenarioID]);

  const performedTests = orderDescend(
    preparePerformedTests(performedTestRaw),
    "createdAt"
  );

  return (
    <>
      {failed && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            minHeight: "42px",
          }}
        >
          <AlertMessage message="Failed" />
        </div>
      )}
      {!loading && (
        <StlTable
          dataSource={performedTests}
          columns={columns}
          loading={loading}
          pagination={{ position: ["topLeft"] }}
          onChange={onChangeHandler}
        />
      )}

      {performedTestItemSelected && (
        <StlModal
          title="Execution Detail"
          open={isVisible}
          destroyOnClose={true}
          maskClosable={true}
          width={"70%"}
          footer={null}
          onCancelHandle={() => {
            hideModal();
            dispatch(getPerformedTestsByIdScenario(params.scenarioID));
          }}
        >
          <PerformedTestDetail detail={performedTestItemSelected} />
        </StlModal>
      )}
    </>
  );
};

export default VoicePerformedTests;
