import { SCENARIO_API_ENDPOINT } from "utils";
import { fetchWrapper } from "utils/fetchWrapper";
export const MSISDN_URL = SCENARIO_API_ENDPOINT + "msisdn/";

const getAll = () => {
  return fetchWrapper.post(MSISDN_URL + "search", { criteria: [] });
};
const getOne = (id) => {
  return fetchWrapper.get(MSISDN_URL + id);
};
const create = (newMsisdn) => {
  return fetchWrapper.post(MSISDN_URL, newMsisdn);
};
const update = (editedMsisdn) => {
  return fetchWrapper.post(MSISDN_URL, editedMsisdn);
};
const remove = (id) => {
  return fetchWrapper.delete(MSISDN_URL + id);
};

const MsisdnService = {
  getAll,
  getOne,
  create,
  update,
  remove,
};

export default MsisdnService;
