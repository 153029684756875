import React, { useEffect, useState } from "react";
import { Form, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deepCopy, updateElementById } from "utils";
import { useParams } from "react-router-dom";
import { addStep, deleteStep } from "features/slices/voice/voiceScenariosSlice";
import {
  editVoiceScenario,
  getAllBackgroundScenarios,
  getOneVoiceScenarioById,
} from "features/slices/voice/voiceScenariosThunk";

import StlTable from "components/shared/StlTable";
import AlertMessage from "components/shared/AlertMessage";
import StlButton from "components/shared/StlButton";
import { useHover } from "hooks/useHover";
import { getVoiceStepsColumns } from "./VoiceStepsColumns";
import { VoiceStepsEditableCell } from "./VoiceStepsEditableCell";

const VoiceSteps = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [form] = Form.useForm();
  const [stepType, setStepType] = useState(null);

  const { buttonStyle, handleMouseEnter, handleMouseLeave } = useHover();

  const currentScenario = useSelector(
    (store) =>
      store.voiceScenarios.scenarios.filter(
        (scenario) => scenario.id === params.scenarioID
      )[0]
  );

  const stepsRaw = currentScenario ? currentScenario["steps"] : null;

  const loading = useSelector((store) => store.voiceScenarios.loading);
  const failed = useSelector((store) => store.voiceScenarios.failed);

  const [isBeingAdded, setIsBeingAdded] = useState(false);
  const [areMultipleSelected, setAreMultipleSelected] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [selectedSteps, setSelectedSteps] = useState([]);

  useEffect(() => {
    dispatch(getOneVoiceScenarioById(params.scenarioID));
    dispatch(getAllBackgroundScenarios());
  }, [dispatch, params.scenarioID]);

  const onSelectTypeHandler = (value) => {
    form.setFieldsValue({ type: value });
    setStepType(value);
    console.log("value", value);
    console.log("stepType", stepType);
  };

  const addNewRow = (ix) => {
    setIsBeingAdded(true);
    dispatch(addStep({ scenarioID: currentScenario.id, index: ix }));
    setStepType(null);
    form.setFieldsValue({
      text: "",
      type: "",
      stepId: "",
      maxLevenshteinDistance: "",
      errorTime: "",
      warningTime: "",
    });
    setEditingKey("New Step");
  };

  const save = async (record) => {
    await form.validateFields();

    const currentStep = record,
      modifiedStep = form.getFieldsValue(true),
      updatedStep = { ...currentStep, ...modifiedStep };

    let currentScenarioCopy = deepCopy(currentScenario);
    let stepsCopy = deepCopy(stepsRaw);

    //@Todo refactor this part if code, I don't know who wrote this!
    if (updatedStep.id === "New Step") {
      updatedStep.id = null;
    }

    if (record.id === "New Step") {
      updatedStep.id = null;
    }
    //@Todo end refactor this part if code

    const updatedSteps = updateElementById(stepsCopy, record.id, updatedStep);

    currentScenarioCopy.steps = updatedSteps;
    dispatch(editVoiceScenario(currentScenarioCopy)).then(() => {
      dispatch(getOneVoiceScenarioById(params.scenarioID));
    });

    setIsBeingAdded(false);
    setEditingKey("");
  };

  const cancel = (id) => {
    if (isBeingAdded) {
      dispatch(deleteStep({ scenarioID: currentScenario.id, stepID: id }));
      setStepType(null);
    }

    setEditingKey("");
    setIsBeingAdded(false);
  };

  const edit = (record) => {
    setStepType(record.type);
    form.setFieldsValue({
      text: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const deleteRow = async (id) => {
    const scenario = deepCopy(currentScenario);
    const newSteps = scenario.steps.filter((e) => !id.includes(e.id));
    scenario.steps = newSteps;
    await dispatch(editVoiceScenario(scenario));
    await dispatch(getOneVoiceScenarioById(params.scenarioID));
    setAreMultipleSelected(false);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      selectedRows.length > 0
        ? setAreMultipleSelected(true)
        : setAreMultipleSelected(false);
      setSelectedSteps(() => {
        let selectedIDs = [];
        selectedRows.forEach((e) => selectedIDs.push(e.id));
        return selectedIDs;
      });
    },
    getCheckboxProps: (record) => ({
      disabled: ["START", "END"].includes(record.type),
    }),
  };

  const prepareSteps = (stepsRaw) => {
    if (stepsRaw && stepsRaw.length) {
      const copiedSteps = deepCopy(stepsRaw);
      copiedSteps.map((step) => (step.key = step.id));
      return copiedSteps;
    }
    return [];
  };

  const steps = prepareSteps(stepsRaw);

  const columns = getVoiceStepsColumns(
    editingKey,
    onSelectTypeHandler,
    stepType,
    areMultipleSelected,
    isBeingAdded,
    addNewRow,
    edit,
    save,
    deleteRow,
    cancel,
    buttonStyle,
    handleMouseEnter,
    handleMouseLeave
  );

  return (
    <Form form={form}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          minHeight: "42px",
        }}
      >
        {failed ? <AlertMessage /> : null}
        {areMultipleSelected ? (
          <Popconfirm
            title="Sure to delete？"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteRow(selectedSteps)}
          >
            <StlButton
              buttonStyle={{ marginRight: "10px" }}
              danger
              buttonType="primary"
              buttonShape="default"
              ghost={false}
            >
              {selectedSteps.length > 1
                ? "Delete Selected Steps"
                : "Delete Selected Step"}
            </StlButton>
          </Popconfirm>
        ) : null}
      </div>
      {currentScenario ? (
        <StlTable
          components={{
            body: {
              cell: VoiceStepsEditableCell,
            },
          }}
          rowSelection={rowSelection}
          loading={loading}
          dataSource={steps}
          columns={columns}
        />
      ) : (
        <h3>Scenario not found</h3>
      )}
    </Form>
  );
};

export default VoiceSteps;
