import { createSlice } from "@reduxjs/toolkit";

const name = "personalInfoSlice";
const initialState = createInitialState();
const reducers = createReducers();

export const personalInfoSlice = createSlice({
  name: name,
  initialState,
  reducers,
});

export const { setNameOfUser, setActualIdToken, setUserEmail } =
  personalInfoSlice.actions;

export default personalInfoSlice.reducer;

function createInitialState() {
  return {
    name: "",
    userEmail: null,
    actualIdToken: null,
  };
}

function createReducers() {
  return {
    setNameOfUser: (state, action) => {
      state.name = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setActualIdToken: (state, action) => {
      state.actualIdToken = action.payload;
    },
  };
}
