import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Form, Popconfirm, Space } from "antd";

import {
  addScheduling,
  deleteScheduling,
} from "features/slices/display/displaySchedulingsSlice";
import {
  editSchedulesByScenarioId,
  getAllSchedulesByScenarioId,
} from "features/slices/display/displaySchedulingsThunk";
import { prepareScheduling } from "utils/transform";

import StlCron from "components/shared/StlCron";
import StlModal from "components/shared/StlModal/StlModal";
import StlButton from "components/shared/StlButton";
import { useHover } from "./useHover";

const useDisplayScheduling = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();

  const loading = useSelector((store) => store.displaySchedulings.loading);
  const schedulingsRaw = useSelector(
    (store) => store.displaySchedulings.schedulings
  );
  const columnsRaw = useSelector((store) => store.displaySchedulings.columns);

  const [editingKey, setEditingKey] = useState("");
  const [isBeingAdded, setIsBeingAdded] = useState(false);
  const [newCronRegex, setNewCronRegex] = useState("");
  const [visible, setVisible] = useState(false);

  const onChangeHandle = (cronRegex) => {
    setNewCronRegex(cronRegex);
  };

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const { buttonStyle, handleMouseEnter, handleMouseLeave } = useHover();

  useEffect(() => {
    dispatch(getAllSchedulesByScenarioId(params.scenarioID));
  }, [dispatch, params.scenarioID]);

  const schedulings = prepareScheduling(schedulingsRaw);

  const prepareColumns = (data) => {
    const columnsCopied = JSON.parse(JSON.stringify(data));

    const actionsToAdd = {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      align: "center",
      render: (_, record) =>
        record.key === editingKey ? (
          <>
            <StlButton iconName="tool" onClickHandler={showModal} />

            {visible && (
              <StlModal
                title="Cron expression Tool"
                width={700}
                open={visible}
                onOkHandle={() => {
                  form.setFieldsValue({
                    scheduling: newCronRegex,
                  });
                  hideModal();
                }}
                onCancelHandle={hideModal}
              >
                <StlCron
                  value={record.scheduling}
                  onChangeHandle={onChangeHandle}
                />
              </StlModal>
            )}

            <StlButton
              iconName="save"
              buttonStyle={{
                marginRight: 8,
              }}
              onClickHandler={() => save(record.key)}
            />

            <Popconfirm title="Sure to cancel?" onConfirm={() => cancel()}>
              <StlButton
                iconName="cancel"
                buttonStyle={buttonStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </Popconfirm>
          </>
        ) : (
          <Space>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => deleteRow(record.key)}
            >
              <StlButton iconName="delete" />
            </Popconfirm>
            <StlButton iconName="edit" onClickHandler={() => edit(record)} />
          </Space>
        ),
    };

    columnsCopied.push(actionsToAdd);
    columnsCopied.forEach((e) => {
      if (e.dataIndex === "scheduling") {
        e.editable = true;
      }
    });

    return columnsCopied.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "number" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editingEnabled: record.key === editingKey,
        }),
      };
    });
  };

  //console.log("columnsRaw", columnsRaw)
  const columns = prepareColumns(columnsRaw);
  //console.log("columns", columns)

  const addNewScheduling = () => {
    form.setFieldsValue({
      scheduling: "",
    });
    setIsBeingAdded(true);
    dispatch(addScheduling());
    const theKey = schedulingsRaw.length;
    setEditingKey(theKey);
  };

  const edit = (record) => {
    setEditingKey(record.key);
    form.setFieldsValue({
      ...record,
    });
  };

  const save = async (key) => {
    const row = await form.validateFields();
    let copiedSchedulings = JSON.parse(JSON.stringify(schedulingsRaw));
    copiedSchedulings.splice(key, 1, row);
    await dispatch(
      editSchedulesByScenarioId({
        schedulings: copiedSchedulings,
        scenarioID: params.scenarioID,
      })
    );
    await dispatch(getAllSchedulesByScenarioId(params.scenarioID));
    setEditingKey("");
    setIsBeingAdded(false);
  };

  const cancel = () => {
    setEditingKey("");
    if (isBeingAdded) {
      dispatch(deleteScheduling());
      setIsBeingAdded(false);
    }
  };

  const deleteRow = async (key) => {
    const copiedSchedulings = JSON.parse(JSON.stringify(schedulingsRaw));
    copiedSchedulings.splice(key, 1);
    await dispatch(
      editSchedulesByScenarioId({
        schedulings: copiedSchedulings,
        scenarioID: params.scenarioID,
      })
    );
    await dispatch(getAllSchedulesByScenarioId(params.scenarioID));
  };

  return { form, addNewScheduling, loading, columns, schedulings };
};
export default useDisplayScheduling;
